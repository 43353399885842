import styled from "styled-components";

export const StyledCardSlide = styled.div`
  height: fit-content;
  min-width: 32rem;
  max-width: 32rem;
  background-color: #fff;
  border-top: 0.5px solid rgba(0, 0, 0, 0.1);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 2.4rem 3.2rem;
  border-radius: 0.8rem;
  // border: 2px solid white;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  .Cabecalho {
    display: flex;
    justify-content: space-between;
    img {
      width: 2.4rem;
    }
  }
  .Titulo {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    h2 {
      display: flex;
      flex-direction: column;
      color: #252a3d;
      font-family: "Work Sans";
      font-size: 2.4rem;
      font-weight: 700;
      line-height: 100%;
      overflow: hidden;
      height: 2.4rem;
    }
    h3 {
      color: #606780;
      font-family: "Work Sans";
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: 0.16px;
    }
  }
  .tagCargo {
    display: flex;
    gap: 0.8rem;
  }
  .flexDe4slots {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .flexde2slots {
      display: flex;

      div {
        width: 50%;
        display: flex;
        flex-direction: column;
        font-family: "Work Sans";
        gap: 0.4rem;
        h3 {
          color: #606780;
          font-weight: 700;
          font-size: 1.6rem;
        }
        p {
          color: #606780;
          font-size: 1.6rem;
        }
      }
    }
  }
  button {
    margin-top: auto;
    display: flex;
    padding: 0.8rem 1.6rem;
    align-items: center;
    gap: 1.6rem;
    cursor: pointer;
    border-radius: 0.5rem;
    border: 1px solid #1b2cc1;
    font-family: "Work Sans";
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.16px;
    background-color: transparent;
    color: #1b2cc1;
    width: 70%;
  }
`;
