import styled from "styled-components";

export const StyledAbout = styled.main`
  .firstSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f2f3ff;
    /* background-image: url("../assets/background_hero_features.png"); */
    background-repeat: no-repeat;

    .tittleAndSubtittle {
      padding: 12.8rem 0;
      display: flex;
      flex-direction: column;
      gap: 3.2rem;
      height: fit-content;
      width: 71.3rem;
      .tittle {
        color: #fff;
        text-align: center;
        font-family: "Work Sans";
        font-size: 6.4rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      p {
        color: #fff;
        text-align: center;
        font-family: "Work Sans";
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.16px;
      }
    }

    .infoCards {
      display: flex;
      gap: 2.4rem;
      height: 34.1rem;
      width: 105.6rem;
      margin-bottom: 9.6rem;
    }

    .ourHistory {
      display: grid;
      grid-template: 10.6rem 1fr / repeat(2, 1fr);
      column-gap: 10rem;
      width: 80%;
      margin: 0 auto;
      height: 65.7rem;
      padding: 9.6rem 0px;
      flex-direction: column;
      align-items: center;
      h2 {
        grid-row: 1/2;
        grid-column: 1/2;
        color: #454ed1;
        font-family: "Work Sans";
        font-size: 6.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 64px */
        letter-spacing: -2.56px;
      }
      p {
        grid-row: 2/3;
        grid-column: 1/2;
        color: #606780;
        font-family: "Work Sans";
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.16px;
      }
      img {
        grid-row: 1/3;
        grid-column: 2/3;
      }
    }
  }

  .secondSection {
    position: relative;
    background-image: url("./assets/How_works.jpg");
    background-repeat: no-repeat;
    .flexSecond {
      padding-top: 9.6rem;
      padding-bottom: 6.4rem;
      display: flex;
      flex-direction: column;
      gap: 6.4rem;

      h2 {
        color: #454ed1;
        text-align: center;
        font-family: "Work Sans";
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 64px */
        letter-spacing: -2.56px;
      }
      .flexTutorial {
        display: flex;
        width: 80%;
        margin: 0 auto;
        gap: 10rem;
      }
    }
    .simple {
      text-align: center;
      color: #606780;
      text-align: center;
      font-family: "Work Sans";
      font-size: 2rem;
      font-style: italic;
      font-weight: 400;
      padding-bottom: 2rem;
      line-height: 140%; /* 28px */
    }
    .flexStatistcs {
      position: absolute;
      display: flex;
      gap: 2.4rem;
      width: 80%;
      left: 50%;
      transform: translateX(-50%);
      top: 70%;
    }
  }
  .thirdSection {
    padding-top: 9.6rem;
    .flexFAQ {
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
      h2 {
        color: #454ed1;
        text-align: center;
        font-family: "Work Sans";
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 64px */
        letter-spacing: -2.56px;
      }
    }
  }

  .fourthSection {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
    background-color: #c5c8db;

    .Fjustify {
      margin: 0 auto;
      width: 80%;
    }
    .flexAlign {
      width: 100%;
      display: flex;
      gap: 2.4rem;
    }

    .formtittlefourth {
      color: #454ed1;
      text-align: center;
      font-family: "Work Sans";
      font-size: 6.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 64px */
      letter-spacing: -2.56px;
      padding-bottom: 4.8rem;
    }

    #formMensagem {
      resize: none;
      display: flex;
      padding: 1.2rem 1.6rem;
      align-items: center;
      gap: 0.8rem;
      border-radius: 0.8rem;
      border: 0.1rem solid #d0d5dd;
      background-color: #fff;
      box-shadow: 0 0.1rem 0.2rem 0 rgba(16, 24, 40, 0.05);
    }

    form {
      padding: 6.8rem;
      min-width: 61.6rem;
      height: fit-content;
      display: flex;
      flex-direction: column;
      border-radius: 3.2rem;
      background-color: #f7f8ff;
      box-shadow: 0 0.4rem 0.4rem 0 rgba(0, 0, 0, 0.25);

      h1 {
        color: #101828;
        font-family: "Work Sans";
        font-size: 4.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 48px */
        letter-spacing: -0.96px;
        margin-bottom: 2rem;
      }
      p {
        margin-bottom: 4.8rem;
        color: #667085;
        font-family: "Work Sans";
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.16px;
      }
      .formFlexGlobal {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
        margin-bottom: 3.2rem;
      }
      .formFlex {
        display: flex;
        flex-direction: column;
        gap: 0.6rem;

        label {
          color: #344054;
          font-family: "Inter", sans-serif;
          font-size: 1.4rem;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }

        input {
          display: flex;
          padding: 1.2rem 1.6rem;
          align-items: center;
          gap: 0.8rem;
          border-radius: 0.8rem;
          border: 0.1rem solid #d0d5dd;
          background-color: #fff;
          box-shadow: 0 0.1rem 0.2rem 0 rgba(16, 24, 40, 0.05);
        }
      }
      .formCheck {
        display: flex;
        flex-direction: row;
        gap: 1.2rem;
        align-items: center;
        label {
          color: #667085;
          font-family: "Work Sans";
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          letter-spacing: 0.16px;
        }
        em {
          all: initial;
          text-decoration-line: underline;
        }
      }

      button {
        width: 12.9rem;
        height: 4.3rem;
        display: flex;
        padding: 1.6rem 4rem;
        align-items: center;
        gap: 1.6rem;
        border-radius: 0.8rem;
        background-color: #1b2cc1;
        color: #fff;
        text-align: center;
        font-family: "Work Sans";
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.16px;
      }
    }
    .squareEmail {
      height: 55rem;
      width: 100%;
      position: relative;

      .baloon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      .centralInfoSquare {
        position: absolute;
        left: 50%;
        top: 45%;
        transform: translate(-50%, -50%);
      }

      .flexEmailSquare {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        width: 30.9rem;
        .emailSquare {
          color: #000;
          font-family: "Work Sans";
          font-size: 48px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%; /* 48px */
          letter-spacing: -0.96px;
        }
        p {
          color: #667085;
          font-family: "Work Sans";
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          letter-spacing: 0.16px;
        }
      }
      button {
        cursor: pointer;
        margin-top: 3.2rem;
        display: flex;
        padding: 1.2rem 2.4rem;
        align-items: center;
        gap: 1.6rem;
        border-radius: 0.8rem;
        border: 1px solid #1b2cc1;
        background-color: #fff;

        a {
          all: initial;
          color: #1b2cc1;
          text-align: center;
          font-family: "Work Sans";
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          letter-spacing: 0.16px;
        }
      }
    }
  }
`;
