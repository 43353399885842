import styled from "styled-components";

export const StyledCardPayment = styled.form`
  display: flex;
  width: 46.2rem;
  padding: 64px var(--Scale-6, 48px) var(--Scale-6, 48px) var(--Scale-6, 48px);
  flex-direction: column;
  gap: var(--Scale-2, 16px);
  border-radius: var(--Scale-2, 16px);
  background: var(--White-900, #fff);

  /* Drop shadow/lg */
  box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.25);
  .flexTittlePlan {
    display: flex;
    flex-direction: column;
    h1 {
      color: var(--Grey-700, #33394d);
      font-family: "Work Sans";
      font-size: 3.6rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.72px;
    }
    p {
      color: var(--Grey-400, #7e849e);
      font-family: "Work Sans";
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .middleTittle {
    color: var(--Grey-500, #606780);
    font-family: "Work Sans";
    font-size: 4.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: 9rem; /* 195.652% */
  }
  .flexMiddleTittle {
    display: flex;
    height: 9rem;
    h3 {
      align-self: center;
      color: var(--Grey-400, #7e849e);
      font-family: "Work Sans";
      font-size: 2rem;
      font-style: normal;
      font-weight: 500;
      line-height: 3.8rem; /* 190% */
      margin-right: 0.5rem;
    }
    h2 {
      align-self: flex-end;
      color: var(--Grey-500, #606780);
      font-family: "Work Sans";
      font-size: 6.4rem;
      font-style: normal;
      font-weight: 600;
      /* line-height: 9rem; */
      line-height: 1;
    }
    h5 {
      align-self: flex-end;
      color: var(--Grey-400, #7e849e);
      font-family: "Work Sans";
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 500;
      line-height: 2.4rem; /* 150% */
    }
  }
  .flexAdvantages {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    margin-bottom: 3.2rem;

    .advantages {
      display: flex;
      align-items: center;
      gap: 1.2rem;

      img {
        width: 2rem;
        height: 2rem;
      }
      .active {
        color: var(--Grey-500, #606780);
        font-family: "Work Sans";
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.4rem; /* 150% */
      }
      .disable {
        color: var(--Grey-300, #a0a5bd);
        font-family: "Work Sans";
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.4rem; /* 150% */
      }
    }
  }
  button {
    background-color: transparent;
    width: 15.1rem;
    display: flex;
    height: 5.8rem;
    padding: 2rem 3.2rem;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    cursor: pointer;
    color: var(--Primary-500, #1b2cc1);
    font-family: "Work Sans";
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.8rem; /* 175% */
    border-radius: 6px;
    border: 1px solid var(--Primary-500, #1b2cc1);
  }
`;
