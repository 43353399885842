import styled from "styled-components";

export const StyledCard = styled.div`
  position: relative;
  display: flex;
  background: #fff;
  box-shadow: 0 0.2rem 0.8rem 0 rgba(0, 0, 0, 0.25);
  // border: 0.1rem solid $--grey-500;
  border-radius: var(--Scale-3);
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  padding: 3.2rem 2.4rem 3.2rem 2.4rem;
  text-align: justify;
  gap: var(--Scale-2);
  /* overflow: hidden; */
  max-height: 57.9rem;

  .flexTags {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.8rem;
    width: 100%;
  }

  .imageAndLocal {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    img {
      height: 3.2rem;
      width: 3.2rem;
      fill: red;
    }
    h4 {
      width: var(--Scale-20);
      color: var(--Grey-500, #606780);
      text-align: right;
      font-family: "Work Sans", sans-serif;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 93%;
    }
  }
  .flexNameAndSuperior {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    .contestName {
      color: var(--Grey-900);
      font-family: "Work Sans", sans-serif;
      font-size: 2.4rem;
      font-style: normal;
      font-weight: 700;
      line-height: 103.811%;
    }
    .flexSuperior {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      width: 100%;
      .superiorSelected {
        color: var(--Grey-500, #606780);
        font-family: "Work Sans", sans-serif;
        font-size: 1.6rem;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
      }
      img {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }
  .tripleData {
    display: flex;
    padding: 1.6rem 0;
    width: 100%;
    justify-content: space-between;
    border: 1px solid #c5c8db;
    border-left: none;
    border-right: none;

    h2 {
      color: var(--Grey-700);
      text-align: center;
      font-family: "Work Sans", sans-serif;
      font-size: 3.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.2058rem;
      height: 4.5rem;
      /* overflow: hidden; */
    }
    p {
      color: var(--Grey-500);
      font-family: "Work Sans", sans-serif;
      font-size: 1.5244rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .miscData {
    margin-bottom: 1.6rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    h4 {
      color: var(--Grey-500);
      font-family: "Work Sans", sans-serif;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      // line-height: 150%;
      strong {
        font-style: normal;
        font-weight: 700;
      }
    }
    .misclocation {
      display: flex;
      gap: 0.4rem;
      align-items: center;

      img {
        width: 1.1rem;
        height: 1.1rem;
      }
      p {
        color: var(--Grey-500);
        font-family: "Work Sans", sans-serif;
        font-size: 1.6rem;
      }
    }
  }

  a {
    text-decoration: none;
  }
  .confiraConcurso {
    position: relative;
    width: 22.2rem;
    height: 4.7rem;
    background-color: transparent;
    display: flex;
    padding: 1.2rem 2.4rem;
    align-items: center;
    gap: 0.8rem;
    border-radius: var(--Radius-md);
    border: 0.1rem solid var(--Primary-500);
    padding: 1.2rem 2.4rem;
    color: var(--Primary-500);
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    cursor: pointer;

    img {
      width: 1.6rem;
      height: 1.6rem;
    }

    &:hover {
      background-color: var(--Primary-500);
      color: var(--Primary-100, #e6e5ff);

      .svg1 {
        fill: white;
      }
      .svg2 {
        fill: white;
      }
    }
  }
  .popUpMakeAPlan {
    width: 22rem;
    border-radius: 0.4rem;
    background-color: rgba(237, 238, 250, 0.9);
    padding: 1rem 1.6rem 1rem 1.2rem;
    position: absolute;
    color: black;
    font-family: "Work Sans", sans-serif;
    font-size: 1.6rem;
    top: 45.5rem;
    left: 40%;
    right: 0;
    z-index: 99;
    border: 1px solid var(--Grey-200);

    a {
      color: var(--Primary-500);
    }
    .btn-close {
      position: absolute;
      left: calc(100% - 0.3rem);
      top: 0.3rem;
      transform: translateX(-100%);
      background-color: transparent;
      border: none;
      cursor: pointer;

      img {
        width: 1.6rem;
      }
    }
  }
`;
