import React from "react";
import editalPublicado from "../assets/editalPublicado.svg";
import inscricoesAbertas from "../assets/inscricoesAbertas.svg";
import inscricoesEncerradas from "../assets/inscricoesEncerradas.svg";
import { StyledTag } from "../styles/Tag";

function Tag({ classname, nome }) {
  return (
    <StyledTag className={`Tag ${classname}`}>
      {classname !== "tagNormal" ? (
        <img
          src={
            classname === "editalPublicado"
              ? editalPublicado
              : classname === "inscricoesAbertas"
              ? inscricoesAbertas
              : classname === "inscricoesEncerradas"
              ? inscricoesEncerradas
              : null
          }
          alt={nome}
        />
      ) : null}
      <p>{nome}</p>
    </StyledTag>
  );
}

export default Tag;
