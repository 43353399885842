import { StyledTutorialBox } from "../styles/TutorialBox.js";

function TutorialBox({ passo, image, alt, tittle, subtittle }) {
  return (
    <StyledTutorialBox className="TutorialBox">
      <h5>{`Passo ${passo}`}</h5>
      <img src={image} alt={alt} />
      <h4>{tittle}</h4>
      <p>{subtittle}</p>
    </StyledTutorialBox>
  );
}

export default TutorialBox;
