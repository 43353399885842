import { React, useState, useRef, useEffect } from "react";
import { StyledSecondSearchBar } from "../styles/SecondSearchBar";
import logoHeader from "../assets/logoHeader.svg";
import briefcase from "../assets/briefcase.svg";
import task from "../assets/task.svg";
import caretDown from "../assets/caretDown.svg";
import lupa from "../assets/lupa.svg";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import Select, { components } from "react-select";

function SecondSearchBar({ page, searchReset, setSearchReset }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [placeholder, setPlaceholder] = useState("Pesquise por um cargo");
  const [selected, setSelected] = useState(page);
  const selectRef = useRef();
  const navigate = useNavigate();
  let encodedSearchTerm = "";

  const openMenu = () => {
    if (selectRef.current) {
      selectRef.current.focus();
      setMenuOpen(true);
    }
  };

  const customDropdownIndicator = () => (
    <img
      className="custom-dropdown-indicator"
      onClick={openMenu}
      src={caretDown}
      alt="dropdown"
    />
  );

  const options = [
    { value: "Cargos", label: "Cargos", image: briefcase },
    { value: "Concursos", label: "Concursos", image: task },
  ];

  const [selectedOption, setSelectedOption] = useState(
    page === "Cargos" ? options[0] : options[1]
  );

  const customSingleValue = ({ data }) => (
    <div className="custom-single-value" onClick={openMenu}>
      <img
        src={data.image}
        alt={data.label}
        className="custom-single-value-image"
      />
      <p>{data.label}</p>
    </div>
  );

  const customOption = (props) => {
    return (
      <components.Option {...props}>
        <img
          src={props.data.image}
          alt={props.data.label}
          style={{ width: "1.6rem", height: "1.6rem", marginRight: "0.8rem" }}
        />
        <p style={{ fontSize: "1.6rem" }}>{props.data.label}</p>
      </components.Option>
    );
  };

  function capturingSearchTerm(term) {
    ReactGA.event({
      category: page === "Cargos" ? "Search in offices" : "Search in contests",
      action: "search_term",
      label: term,
    });
  }

  const searchFunction = (e) => {
    e.preventDefault();
    if (userInput === "") {
      setTimeout(() => {
        setPlaceholder("Por favor digite algo");
      }, 1000);
    } else {
      if (selected === "Cargos") {
        capturingSearchTerm(userInput);
        encodedSearchTerm = encodeURIComponent(userInput);
        navigate(`/cargos/${encodedSearchTerm}`);
      } else {
        capturingSearchTerm(userInput);
        encodedSearchTerm = encodeURIComponent(userInput);
        navigate(`/concursos/${encodedSearchTerm}`);
      }
      setSearchReset(!searchReset);
    }
  };

  function changeSearch(e) {
    setSelectedOption(e.value);
    setSelected(e.value);
    setMenuOpen(false);
    setUserInput("");
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && selectRef.current.controlRef !== event.target) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function navegationPage(page) {
    navigate(page);
  }

  return (
    <StyledSecondSearchBar
      onSubmit={searchFunction}
      className="SecondSearchBar"
    >
      <img
        className="logoSearch"
        src={logoHeader}
        alt="logo"
        onClick={() => navegationPage("/")}
      />
      <div className="flexSearch">
        <Select
          menuIsOpen={menuOpen}
          openMenuOnFocus={true}
          defaultValue={selectedOption}
          options={options}
          ref={selectRef}
          onChange={changeSearch}
          components={{
            Option: customOption,
            SingleValue: customSingleValue,
            DropdownIndicator: customDropdownIndicator,
            IndicatorSeparator: () => null,
          }}
          classNamePrefix="select"
          styles={{
            control: (provided, state) => ({
              ...provided,
              // backgroundColor: "#f3f4f6",
              backgroundColor: "transparent",
              border: "transparent",
              borderRadius: "4px",
              width: "20rem",
            }),
            option: (provided, state) => ({
              ...provided,
              display: "flex",
              alignItems: "center",
              padding: "8px 12px",
              cursor: "pointer",
              backgroundColor: state.isSelected ? "#e8e9f3" : "#EDEEFA",
              color: "#495057",
              "&:hover": {
                backgroundColor: "#A0A5BD",
              },
            }),
            input: (provided) => ({
              ...provided,
              margin: 0,
              padding: 0,
            }),
            singleValue: (provided) => ({
              ...provided,
              display: "flex",
              alignItems: "center",
              color: "#495057",
            }),
          }}
        />
        <div className="pipe">|</div>
        <div className="flexInput">
          <input
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            type="text"
            placeholder={placeholder}
          />
        </div>
        <div className="pipe">|</div>
        <button type="submit" className="lupa">
          <img src={lupa} alt="lupa" />
        </button>
      </div>
    </StyledSecondSearchBar>
  );
}

export default SecondSearchBar;
