import React from "react";
import { StyledUserAccount } from "../styles/UserAccountAssinatura.js";
import BoxUserAccount from "./BoxUserAccount.jsx";
import paymentPlaceholder from "../assets/paymentPlaceholder.png";
import { Link } from "react-router-dom";

function UserAccountAssinatura() {
  return (
    <StyledUserAccount>
      <h1>Assinatura</h1>
      <BoxUserAccount>
        <div>
          <span>Plano Premium Plus</span>
          <p>
            Buscas ilimitadas; busca por cargos e concursos; acesso a todos os
            filtros; informações completa sobre os concursos; e 25% de desconto.
          </p>
        </div>
        <hr />
        <Link to="#">Alterar plano</Link>
      </BoxUserAccount>
      <BoxUserAccount>
        <div>
          <span>Próximo pagamento</span>
          <p>27 de novembro de 2024</p>
          <div className="flexCard">
            <img
              className="bandeira"
              src={paymentPlaceholder}
              alt="paymentPlaceholder"
            />
            <p>•••• •••• •••• 1234</p>
          </div>
        </div>
        <hr />
        <Link to="#">Gerenciar a forma de pagamento</Link>
        <hr />
        <Link to="#">Resgatar código promocional</Link>
        <hr />
        <Link to="#">Ver histórico de pagamento</Link>
      </BoxUserAccount>
      <BoxUserAccount>
        <Link
          style={{ color: "#A11722", textAlign: "center", width: "100%" }}
          to="#"
        >
          Cancelar assinatura
        </Link>
      </BoxUserAccount>
    </StyledUserAccount>
  );
}

export default UserAccountAssinatura;
