import styled from "styled-components";

export const StyledFilter = styled.aside`
  .filterClean {
    margin: 1rem 0 0.5rem;
    background-color: #fff;
    padding: 0.4rem 1rem;
    color: $--primary-500;
    font-family: "Work Sans", sans-serif;
    border-radius: 0.4rem;
    border-width: 0.1rem;
    border-color: $--primary-500;
    border-style: solid;
    font-size: 1.6rem;
    cursor: pointer;
  }
  .flex {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 2rem;
    color: #474747;
    font-family: "Work Sans", sans-serif;

    img {
      width: 1.5rem;
    }

    h3 {
      font-weight: normal;
    }
  }
`;
