import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { signup } from "../actions/auth";
import axios from "axios";
import { StyledSignUp } from "../styles/SignUp";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const schema = Yup.object().shape({
  username: Yup.string().required("* É necessário inserir um nome válido."),
  lastName: Yup.string().required(
    "* É necessário inserir um sobrenome válido."
  ),
  email: Yup.string()
    .email("* É necessário inserir um e-mail válido.")
    .required("* Email é obrigatório"),
  password: Yup.string()
    .min(6, "* A senha deve ter no mínimo 6 caracteres")
    .matches(
      /(?=.*[A-Z])/,
      "* A senha deve conter pelo menos uma letra maiúscula"
    )
    .matches(
      /(?=.*[a-z])/,
      "* A senha deve conter pelo menos uma letra minúscula"
    )
    .matches(/(?=.*[0-9])/, "* A senha deve conter pelo menos um número")
    .matches(
      /(?=.*[@$!%*?&])/,
      "* A senha deve conter pelo menos um caractere especial"
    )
    .matches(/^(?!.*\b(?:123456|password|qwerty)\b).*$/, "* Senha muito fraca")
    .required("* Senha é obrigatória"),
  re_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "* As senhas devem ser iguais.")
    .required("* Confirmação de senha é obrigatória"),
  termos: Yup.boolean()
    .oneOf(
      [true],
      "* é necessário aceitar os termos de serviço para se registrar."
    )
    .required("* é necessário aceitar os termos de serviço para se registrar."),
});

const SignUp = ({ signup, isAuthenticated }) => {
  document.title = `Cadastro - Alô Concursos`;
  const URL = process.env.REACT_APP_API_URL;
  const [accountCreated, setAccountCreated] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      username: "",
      lastName: "",
      email: "",
      password: "",
      re_password: "",
    },
  });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const { username, lastName, email, password, re_password } = data;
    const response = await signup(
      username,
      lastName,
      email,
      password,
      re_password
    );

    if (response.success) {
      setAccountCreated(true);
      setErrorMessage(null);
    } else {
      if (response.error.email[0] === "user with this email already exists.") {
        setErrorMessage("* O e-mail informado já está registrado.");
      }
    }
  };

  const continueWithGoogle = async () => {
    try {
      const res = await axios.get(
        `${URL}/auth/o/google-oauth2/?redirect_uri=${URL}`
      );

      window.location.replace(res.data.authorization_url);
    } catch (err) {
      console.error("Erro ao tentar registrar com o Google", err);
    }
  };

  const continueWithFacebook = async () => {
    try {
      const res = await axios.get(
        `${URL}/auth/o/facebook/?redirect_uri=${URL}`
      );

      window.location.replace(res.data.authorization_url);
    } catch (err) {
      console.error("Erro ao tentar registrar com o Facebook", err);
    }
  };

  return (
    <StyledSignUp>
      {accountCreated ? (
        <div className="OldSignUp" style={{ marginBottom: "4.8rem" }}>
          <svg
            className="svgScreen"
            xmlns="http://www.w3.org/2000/svg"
            width="96"
            height="96"
            viewBox="0 0 96 96"
            fill="none"
          >
            <g clipPath="url(#clip0_2463_206)">
              <path
                d="M48 0C21.52 0 0 21.52 0 48C0 74.48 21.52 96 48 96C74.48 96 96 74.48 96 48C96 21.52 74.48 0 48 0ZM68.68 46.48L60 53.56L63.6 64.48C64.08 65.96 63.6 67.6 62.36 68.52C61.12 69.48 59.44 69.52 58.12 68.68L48.04 62.12L38.12 68.76C37.52 69.16 36.8 69.36 36.08 69.36C35.32 69.36 34.52 69.12 33.88 68.64C32.64 67.72 32.12 66.08 32.6 64.6L36.04 53.56L27.32 46.48C26.16 45.48 25.72 43.88 26.24 42.44C26.76 41 28.16 40.04 29.68 40.04H40.68L44.56 29.6C45.08 28.16 46.48 27.2 48 27.2C49.52 27.2 50.92 28.16 51.44 29.6L55.32 40.04H66.32C67.84 40.04 69.24 41 69.76 42.44C70.28 43.88 69.84 45.52 68.68 46.52V46.48Z"
                fill="#5FCE1D"
              />
            </g>
            <defs>
              <clipPath id="clip0_2463_206">
                <rect width="96" height="96" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <div className="flexTittleText">
            <h1>Cadastro realizado com sucesso</h1>
            <p>
              Enviamos um e-mail com um link e instruções para você{" "}
              <span>verificar seu e-mail.</span> <br />
              <br /> A confirmação serve para garantir que temos o seu e-mail
              correto cadastrado em nosso sistema. Verifique sua caixa de spam
              ou promoções, caso não apareça na sua caixa principal do seu
              gerenciador de e-mail.
            </p>
          </div>
        </div>
      ) : (
        <div className="OldSignUp">
          <h2>Faça seu registro</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label htmlFor="usernameR">Nome</label>
              <Controller
                name="username"
                control={control}
                render={({ field }) => (
                  <input
                    className={
                      errors.username
                        ? "form-control errorForm"
                        : "form-control"
                    }
                    type="text"
                    placeholder="Insira seu primeiro nome"
                    id="usernameR"
                    {...field}
                  />
                )}
              />
              {errors.username && (
                <div className="error">{errors.username.message}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="last_nameR">Sobrenome</label>
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <input
                    className={
                      errors.lastName
                        ? "form-control errorForm"
                        : "form-control"
                    }
                    type="text"
                    placeholder="Insira seu sobrenome"
                    id="last_nameR"
                    {...field}
                  />
                )}
              />
              {errors.lastName && (
                <div className="error">{errors.lastName.message}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="emailR">Email</label>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <input
                    id="emailR"
                    className={
                      errors.email ? "form-control errorForm" : "form-control"
                    }
                    type="email"
                    placeholder="Insira seu e-mail"
                    {...field}
                  />
                )}
              />
              {errors.email && (
                <div className="error">{errors.email.message}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="passwordR">Senha</label>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <input
                    className={
                      errors.password
                        ? "form-control errorForm"
                        : "form-control"
                    }
                    id="passwordR"
                    type="password"
                    placeholder="••••••••"
                    {...field}
                  />
                )}
              />
              {errors.password && (
                <div className="error">{errors.password.message}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="re_passwordR">Confirme sua senha</label>
              <Controller
                name="re_password"
                control={control}
                render={({ field }) => (
                  <input
                    id="re_passwordR"
                    className={
                      errors.re_password
                        ? "form-control errorForm"
                        : "form-control"
                    }
                    type="password"
                    placeholder="••••••••"
                    {...field}
                  />
                )}
              />
              {errors.re_password && (
                <div className="error">{errors.re_password.message}</div>
              )}
              <div>
                <div className="flexTermos">
                  <Controller
                    name="termos"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input id="termos" type="checkbox" {...field} />
                        <label htmlFor="termos">
                          Eu li e aceito os <span>termos de serviço</span>
                        </label>
                      </>
                    )}
                  />
                </div>
                {errors.termos && (
                  <div className="error">{errors.termos.message}</div>
                )}
              </div>
            </div>
            {errorMessage && <div className="error">{errorMessage}</div>}
            <button type="submit">Registrar</button>
          </form>
          <div className="flexGoogleFacebook">
            <button onClick={continueWithGoogle}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <g clipPath="url(#clip0_2311_64)">
                  <path
                    d="M24.266 12.2765C24.266 11.4608 24.1999 10.6406 24.0588 9.83813H12.74V14.4591H19.2217C18.9528 15.9495 18.0885 17.2679 16.823 18.1056V21.104H20.69C22.9608 19.014 24.266 15.9274 24.266 12.2765Z"
                    fill="#4285F4"
                  />
                  <path
                    d="M12.74 24.0008C15.9764 24.0008 18.7058 22.9382 20.6944 21.1039L16.8274 18.1055C15.7516 18.8375 14.3626 19.252 12.7444 19.252C9.61376 19.252 6.95934 17.1399 6.00693 14.3003H2.01648V17.3912C4.05359 21.4434 8.20278 24.0008 12.74 24.0008Z"
                    fill="#34A853"
                  />
                  <path
                    d="M6.00253 14.3002C5.49987 12.8099 5.49987 11.196 6.00253 9.70569V6.61475H2.01649C0.31449 10.0055 0.31449 14.0004 2.01649 17.3912L6.00253 14.3002Z"
                    fill="#FBBC04"
                  />
                  <path
                    d="M12.74 4.74966C14.4508 4.7232 16.1043 5.36697 17.3433 6.54867L20.7694 3.12262C18.6 1.0855 15.7207 -0.034466 12.74 0.000808666C8.20277 0.000808666 4.05359 2.55822 2.01648 6.61481L6.00252 9.70575C6.95052 6.86173 9.60935 4.74966 12.74 4.74966Z"
                    fill="#EA4335"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2311_64">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <p>Registrar com o Google</p>
            </button>
            <button onClick={continueWithFacebook}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <g clipPath="url(#clip0_2311_71)">
                  <path
                    d="M24.5 12C24.5 5.37258 19.1274 0 12.5 0C5.87258 0 0.5 5.37258 0.5 12C0.5 17.9895 4.8882 22.954 10.625 23.8542V15.4688H7.57812V12H10.625V9.35625C10.625 6.34875 12.4166 4.6875 15.1576 4.6875C16.4701 4.6875 17.8438 4.92188 17.8438 4.92188V7.875H16.3306C14.84 7.875 14.375 8.80008 14.375 9.75V12H17.7031L17.1711 15.4688H14.375V23.8542C20.1118 22.954 24.5 17.9895 24.5 12Z"
                    fill="#1877F2"
                  />
                  <path
                    d="M17.1711 15.4688L17.7031 12H14.375V9.75C14.375 8.80102 14.84 7.875 16.3306 7.875H17.8438V4.92188C17.8438 4.92188 16.4705 4.6875 15.1576 4.6875C12.4166 4.6875 10.625 6.34875 10.625 9.35625V12H7.57812V15.4688H10.625V23.8542C11.8674 24.0486 13.1326 24.0486 14.375 23.8542V15.4688H17.1711Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2311_71">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <p>Registrar com o Facebook</p>
            </button>
          </div>

          <p className="Register">
            Já possui uma conta? <Link to="/login">Fazer login</Link>
          </p>
        </div>
      )}
      {accountCreated && (
        <p className="newWarning">
          <span>Não recebeu o e-mail?</span> Se em 5 minutos você não receber,
          entre em contato através do e-mail{" "}
          <a href="mailto:admin@aloconcursos">admin@aloconcursos.com</a>
        </p>
      )}
    </StyledSignUp>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { signup })(SignUp);
