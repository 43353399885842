import loaderGif from "../assets/loader.gif";
import { StyledLoader } from "../styles/Loader";

function Loader() {
  return (
    <StyledLoader className="Loader">
      <img src={loaderGif} alt="Loading..." />
      <h1>Carregando...</h1>
    </StyledLoader>
  );
}

export default Loader;
