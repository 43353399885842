import { StyledFaq } from "../styles/Faq";
import add_circle from "../assets/add_circle.png";
import minus_circle from "../assets/minus_circle.png";
import { useState } from "react";

function Faq({ question, answer, alt }) {
  const [image, setImage] = useState(add_circle);
  const [animation, setAnimation] = useState("close");

  function animationBegin() {
    if (animation === "close") {
      setAnimation("open");
      setImage(minus_circle);
    } else {
      setAnimation("close");
      setImage(add_circle);
    }
  }

  return (
    <StyledFaq className="Faq">
      <div>
        <h3>{question}</h3>
        <img src={image} alt={alt} onClick={() => animationBegin()} />
      </div>
      <p className={animation}>{answer}</p>
      <hr />
    </StyledFaq>
  );
}

export default Faq;
