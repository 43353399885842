import { StyledCard } from "../styles/Card";
import CardSticky from "../UI/CardSticky";
import Tag from "../UI/Tag";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

// import lock from "../assets/lock.svg";
import {
  convertMoney,
  conversaoData,
  conversaoDia,
  conversaoSalario,
  compararDatasNome,
  compararDatasClasse,
} from "../tools/functions";
import PopUp from "../UI/PopUp";

function Card({
  id,
  working_hours,
  diaDaProva,
  stage,
  icone,
  prefeitura,
  nomeDoCargo,
  vagas,
  banca,
  salario,
  inscricoesAbertas,
  inscricoesEncerradas,
  taxa,
  local,
  setWhatCard,
  tags,
  pagou,
}) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setWhatCard({
      id: id,
    });
  }, []);

  const lista_de_datas = conversaoData(diaDaProva).split("/");

  return (
    <StyledCard className="Card">
      {/* 1 parte - Tags */}
      <div className="flexTags">
        <Tag
          nome={compararDatasNome(inscricoesAbertas, inscricoesEncerradas)}
          classname={compararDatasClasse(
            inscricoesAbertas,
            inscricoesEncerradas
          )}
        />
      </div>
      {/* 2 parte - imagem e local */}
      <div className="imageAndLocal">
        {/* <IconeSVG svg={icone} cor={"red"}/> */}
        <img src={icone} alt="icone" />
        {/* <svg xmlns={icone}></svg> */}
        <h4>{prefeitura}</h4>
      </div>
      {/* 3 parte - Nome e Superior selecionados */}
      <div className="flexNameAndSuperior">
        <h2 className="contestName">{nomeDoCargo}</h2>
        <div className="flexSuperior">
          {/* <p className='superiorSelected'>superior selecionados</p> */}
          {tags
            ? tags.map((item, index) => {
                return (
                  <Tag key={index} nome={item.name} classname={"tagNormal"} />
                );
              })
            : null}
          {/* tag de momento do concurso */}
          {/* edital publicado, inscrições abertas, inscrições encerradas */}
          {/* <Icone imagem={error1}/> */}
        </div>
      </div>
      {/* 4 parte - TripleData */}
      <div className="tripleData">
        <CardSticky
          titulo={vagas === 0 ? "CR" : vagas}
          subTitulo={vagas === 1 ? "Vaga" : vagas === 0 ? "vagas" : "Vagas"}
          balao={vagas === 0 ? "Cadastro de Reserva" : null}
        />
        {/* <CardSticky titulo={banca} subTitulo={"Banca"}/> */}
        <CardSticky titulo={working_hours} subTitulo={"Horas semanais"} />
        <CardSticky
          titulo={`${conversaoSalario(salario)}K+`}
          subTitulo={"Salário"}
        />
      </div>
      {/* 5 parte - miscData */}
      <div className="miscData">
        <h4>
          <strong>Banca: </strong>
          {banca}
        </h4>
        <h4>
          <strong>Inscrições: </strong>
          {conversaoDia(inscricoesAbertas)} a{" "}
          {conversaoData(inscricoesEncerradas)}
        </h4>
        <h4>
          <strong>Taxa: </strong>
          {`${convertMoney(taxa)}`}
        </h4>
        <h4>
          <strong>Prova: </strong>
          {`${lista_de_datas[0]}/${lista_de_datas[1]}/${lista_de_datas[2]}`}
        </h4>
        <div className="misclocation">
          <h4>
            <strong>Local: </strong>
            {local}
          </h4>
        </div>
      </div>
      {/* 6 parte - Botão */}
      {pagou ? (
        <Link to={`/concurso/${id}`}>
          <button className="confiraConcurso">
            <p>Confira o concurso </p>
          </button>
        </Link>
      ) : (
        <button onClick={() => setShow(true)} className="confiraConcurso">
          <p>Confira o concurso </p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="20"
            viewBox="0 0 18 20"
            fill="none"
          >
            <g
              clipPath="url(#clip0_2786_4550)"
              filter="url(#filter0_d_2786_4550)"
            >
              <path
                className="svg1"
                d="M13.6667 6.616V5.66667C13.6667 4.42899 13.175 3.242 12.2998 2.36684C11.4247 1.49167 10.2377 1 9.00001 1C7.76233 1 6.57535 1.49167 5.70018 2.36684C4.82501 3.242 4.33334 4.42899 4.33334 5.66667V6.616C3.73958 6.87514 3.23421 7.30168 2.87901 7.84347C2.52381 8.38527 2.3342 9.01882 2.33334 9.66667V13.6667C2.3344 14.5504 2.68593 15.3976 3.31082 16.0225C3.93572 16.6474 4.78295 16.9989 5.66668 17H12.3333C13.2171 16.9989 14.0643 16.6474 14.6892 16.0225C15.3141 15.3976 15.6656 14.5504 15.6667 13.6667V9.66667C15.6658 9.01882 15.4762 8.38527 15.121 7.84347C14.7658 7.30168 14.2604 6.87514 13.6667 6.616ZM5.66668 5.66667C5.66668 4.78261 6.01787 3.93477 6.64299 3.30964C7.26811 2.68452 8.11596 2.33333 9.00001 2.33333C9.88407 2.33333 10.7319 2.68452 11.357 3.30964C11.9822 3.93477 12.3333 4.78261 12.3333 5.66667V6.33333H5.66668V5.66667ZM14.3333 13.6667C14.3333 14.1971 14.1226 14.7058 13.7476 15.0809C13.3725 15.456 12.8638 15.6667 12.3333 15.6667H5.66668C5.13624 15.6667 4.62754 15.456 4.25246 15.0809C3.87739 14.7058 3.66668 14.1971 3.66668 13.6667V9.66667C3.66668 9.13623 3.87739 8.62753 4.25246 8.25245C4.62754 7.87738 5.13624 7.66667 5.66668 7.66667H12.3333C12.8638 7.66667 13.3725 7.87738 13.7476 8.25245C14.1226 8.62753 14.3333 9.13623 14.3333 9.66667V13.6667Z"
                fill="#1B2CC1"
              />
              <path
                className="svg2"
                d="M9.00004 10.333C8.82323 10.333 8.65366 10.4032 8.52864 10.5283C8.40361 10.6533 8.33337 10.8229 8.33337 10.9997V12.333C8.33337 12.5098 8.40361 12.6794 8.52864 12.8044C8.65366 12.9294 8.82323 12.9997 9.00004 12.9997C9.17685 12.9997 9.34642 12.9294 9.47145 12.8044C9.59647 12.6794 9.66671 12.5098 9.66671 12.333V10.9997C9.66671 10.8229 9.59647 10.6533 9.47145 10.5283C9.34642 10.4032 9.17685 10.333 9.00004 10.333Z"
                fill="#1B2CC1"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_2786_4550"
                x="-1"
                y="0"
                width="20"
                height="20"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_2786_4550"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_2786_4550"
                  result="shape"
                />
              </filter>
              <clipPath id="clip0_2786_4550">
                <rect
                  width="16"
                  height="16"
                  fill="white"
                  transform="translate(1 1)"
                />
              </clipPath>
            </defs>
          </svg>
        </button>
      )}
      {show && <PopUp setShow={setShow} />}
    </StyledCard>
  );
}

export default Card;
