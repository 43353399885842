import styled from "styled-components";

export const StyledFooter = styled.footer`
  width: 100%;
  height: var(--FooterHeight);
  text-align: center;
  padding: 3.2rem;
  font-size: 2rem;
  background-color: #edeefa;
  color: #33394d;
  font-family: "Inter", sans-serif;

  @media (max-width: 572px) {
    display: flex;
    padding: 1.6rem;
    align-items: center;
  }
`;
