import styled from "styled-components";

export const StyledNotFound = styled.main`
  h1 {
    font-size: 4rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-family: "Work Sans", sans-serif;
  }
`;
