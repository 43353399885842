import arrowDown from "../assets/arrowDown.png";
import { useState, useEffect } from "react";
import FilterCategorySquare from "./FilterCategorySquare";
import { StyledFilterSubMenu } from "../styles/FilterSubMenu";
import { useSelector } from "react-redux";

function FilterSubMenu({
  nome,
  submenu,
  setPreInscricao,
  preInscricao,
  setInscricoesAbertas,
  inscricoesAbertas,
  setInscricoesEncerradas,
  inscricoesEncerradas,
  setSuperior,
  superior,
  setFundamental,
  fundamental,
  setMedio,
  medio,
  setMestrado,
  mestrado,
  setDoutorado,
  doutorado,
  cadastroReserva,
  setCadastroReserva,
  vagaUm,
  setVagaUm,
  vagaDoisDez,
  setVagaDoisDez,
  vagaDez,
  setVagaDez,
  vagaVinteCinco,
  setVagaVinteCinco,
  vagaCinquenta,
  setVagaCinquenta,
  vagaCem,
  setVagaCem,
  semRendimentos,
  setSemRendimentos,
  salario1,
  setSalario1,
  salario2,
  setSalario2,
  salario3,
  setSalario3,
  salario4,
  setSalario4,
  salario5,
  setSalario5,
  salario6,
  setSalario6,
  salario7,
  setSalario7,
  salario8,
  setSalario8,
  salario9,
  setSalario9,
  salario10,
  setSalario10,
  salario11,
  setSalario11,
  salario12,
  setSalario12,
  salario13,
  setSalario13,
  salario14,
  setSalario14,
  salario15,
  setSalario15,
  nacional,
  saoPaulo,
  rioDeJaneiro,
  distritoFederal,
  acre,
  alagoas,
  amapa,
  amazonas,
  bahia,
  ceara,
  espiritoSanto,
  goias,
  maranhao,
  matoGrosso,
  matoGrossoDoSul,
  minasGerais,
  para,
  paraiba,
  parana,
  pernambuco,
  piaui,
  rioGrandeDoNorte,
  rioGrandeDoSul,
  rondonia,
  roraima,
  santaCatarina,
  sergipe,
  tocantins,
  setNacional,
  setSaoPaulo,
  setRioDeJaneiro,
  setDistritoFederal,
  setAcre,
  setAlagoas,
  setAmapa,
  setAmazonas,
  setBahia,
  setCeara,
  setEspiritoSanto,
  setGoias,
  setMaranhao,
  setMatoGrosso,
  setMatoGrossoDoSul,
  setMinasGerais,
  setPara,
  setParaiba,
  setParana,
  setPernambuco,
  setPiaui,
  setRioGrandeDoNorte,
  setRioGrandeDoSul,
  setRondonia,
  setRoraima,
  setSantaCatarina,
  setSergipe,
  setTocantins,
  seta,
}) {
  const [setaDown, setSetaDown] = useState("rotate(0deg)");
  const [setaUp, setSetaUp] = useState("rotate(-90deg)");
  const [altura, setAltura] = useState(2.3 * submenu.length);
  const [alturaFechada, setAlturaFechada] = useState(0);
  const user = useSelector((state) => state.auth.user);
  const [isPaid, setPagou] = useState(false);
  // * Variáveis para consumo da API
  const Token = process.env.REACT_APP_TOKEN;
  const URL = process.env.REACT_APP_API_URL;

  async function requisicaoIsPayd(email) {
    const response = await fetch(`${URL}/user/users/?emails=${email}`, {
      headers: {
        Authorization: `Token ${Token}`,
      },
    });
    const data = await response.json();
    return data.results[0].subscription_active;
  }

  useEffect(() => {
    if (user !== null) {
      requisicaoIsPayd(user.email).then((isPaid) => {
        setPagou(isPaid);
      });
    }
  }, [user]);

  function cliqueNaSeta() {
    if (seta === "down") {
      setSetaDown(
        setaDown === "rotate(0deg)" ? "rotate(-90deg)" : "rotate(0deg)"
      );
      setAltura(setaDown === "rotate(0deg)" ? 0 : 2.3 * submenu.length);
    } else {
      setSetaUp(setaUp === "rotate(0deg)" ? "rotate(-90deg)" : "rotate(0deg)");
      setAlturaFechada(setaUp === "rotate(0deg)" ? 0 : 2.3 * submenu.length);
    }
  }

  return (
    <StyledFilterSubMenu>
      <div className="FilterSubMenu" onClick={() => cliqueNaSeta()}>
        <h4>{nome}</h4>
        <img
          className="seta"
          src={arrowDown}
          style={
            seta === "down" ? { transform: setaDown } : { transform: setaUp }
          }
          alt="seta"
        />
      </div>
      <div
        className="flexSquares"
        style={{
          maxHeight: `${seta === "down" ? altura : alturaFechada}rem`,
          overflow: "hidden",
          transition: "max-height 0.5s ease",
        }}
      >
        {submenu.map((categoria, index) => {
          // ESTADO
          return categoria === "Diversos" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={nacional}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "São Paulo" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={saoPaulo}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Rio de Janeiro" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={rioDeJaneiro}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Distrito Federal" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={distritoFederal}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Acre" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={acre}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Alagoas" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={alagoas}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Amapá" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={amapa}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Amazonas" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={amazonas}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Bahia" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={bahia}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Ceará" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={ceara}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Espírito Santo" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={espiritoSanto}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Goiás" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={goias}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Maranhão" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={maranhao}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Mato Grosso" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={matoGrosso}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Mato Grosso do Sul" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={matoGrossoDoSul}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Minas Gerais" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={minasGerais}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Pará" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={para}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Paraíba" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={paraiba}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Paraná" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={parana}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Pernambuco" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={pernambuco}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Piauí" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={piaui}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Rio Grande do Norte" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={rioGrandeDoNorte}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Rio Grande do Sul" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={rioGrandeDoSul}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Rondônia" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={rondonia}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Roraima" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={roraima}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Santa Catarina" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={santaCatarina}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Sergipe" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={sergipe}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : categoria === "Tocantins" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={tocantins}
              setNacional={setNacional}
              setSaoPaulo={setSaoPaulo}
              setRioDeJaneiro={setRioDeJaneiro}
              setDistritoFederal={setDistritoFederal}
              setAcre={setAcre}
              setAlagoas={setAlagoas}
              setAmapa={setAmapa}
              setAmazonas={setAmazonas}
              setBahia={setBahia}
              setCeara={setCeara}
              setEspiritoSanto={setEspiritoSanto}
              setGoias={setGoias}
              setMaranhao={setMaranhao}
              setMatoGrosso={setMatoGrosso}
              setMatoGrossoDoSul={setMatoGrossoDoSul}
              setMinasGerais={setMinasGerais}
              setPara={setPara}
              setParaiba={setParaiba}
              setParana={setParana}
              setPernambuco={setPernambuco}
              setPiaui={setPiaui}
              setRioGrandeDoNorte={setRioGrandeDoNorte}
              setRioGrandeDoSul={setRioGrandeDoSul}
              setRondonia={setRondonia}
              setRoraima={setRoraima}
              setSantaCatarina={setSantaCatarina}
              setSergipe={setSergipe}
              setTocantins={setTocantins}
              liberado={true}
            />
          ) : // ETAPAS
          categoria === "Edital publicado" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={preInscricao}
              setPreInscricao={setPreInscricao}
              setInscricoesAbertas={setInscricoesAbertas}
              setInscricoesEncerradas={setInscricoesEncerradas}
              liberado={isPaid}
            />
          ) : categoria === "Inscrições abertas" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={inscricoesAbertas}
              setPreInscricao={setPreInscricao}
              setInscricoesAbertas={setInscricoesAbertas}
              setInscricoesEncerradas={setInscricoesEncerradas}
              liberado={isPaid}
            />
          ) : categoria === "Inscrições encerradas" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={inscricoesEncerradas}
              setPreInscricao={setPreInscricao}
              setInscricoesAbertas={setInscricoesAbertas}
              setInscricoesEncerradas={setInscricoesEncerradas}
              liberado={isPaid}
            />
          ) : // EDUCAÇÃO
          categoria === "Superior" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={superior}
              setSuperior={setSuperior}
              setFundamental={setFundamental}
              setMedio={setMedio}
              setMestrado={setMestrado}
              setDoutorado={setDoutorado}
              liberado={isPaid}
            />
          ) : categoria === "Fundamental" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={fundamental}
              setSuperior={setSuperior}
              setFundamental={setFundamental}
              setMedio={setMedio}
              setMestrado={setMestrado}
              setDoutorado={setDoutorado}
              liberado={isPaid}
            />
          ) : categoria === "Médio" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={medio}
              setSuperior={setSuperior}
              setFundamental={setFundamental}
              setMedio={setMedio}
              setMestrado={setMestrado}
              setDoutorado={setDoutorado}
              liberado={isPaid}
            />
          ) : categoria === "Mestrado" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={mestrado}
              setSuperior={setSuperior}
              setFundamental={setFundamental}
              setMedio={setMedio}
              setMestrado={setMestrado}
              setDoutorado={setDoutorado}
              liberado={isPaid}
            />
          ) : categoria === "Doutorado" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={doutorado}
              setSuperior={setSuperior}
              setFundamental={setFundamental}
              setMedio={setMedio}
              setMestrado={setMestrado}
              setDoutorado={setDoutorado}
              liberado={isPaid}
            />
          ) : // VAGAS
          categoria === "Cadastro de Reserva" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={cadastroReserva}
              setCadastroReserva={setCadastroReserva}
              setVagaUm={setVagaUm}
              setVagaDoisDez={setVagaDoisDez}
              setVagaDez={setVagaDez}
              setVagaVinteCinco={setVagaVinteCinco}
              setVagaCinquenta={setVagaCinquenta}
              setVagaCem={setVagaCem}
              liberado={isPaid}
            />
          ) : categoria === "1" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={vagaUm}
              setCadastroReserva={setCadastroReserva}
              setVagaUm={setVagaUm}
              setVagaDoisDez={setVagaDoisDez}
              setVagaDez={setVagaDez}
              setVagaVinteCinco={setVagaVinteCinco}
              setVagaCinquenta={setVagaCinquenta}
              setVagaCem={setVagaCem}
              liberado={isPaid}
            />
          ) : categoria === "2 a 10" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={vagaDoisDez}
              setCadastroReserva={setCadastroReserva}
              setVagaUm={setVagaUm}
              setVagaDoisDez={setVagaDoisDez}
              setVagaDez={setVagaDez}
              setVagaVinteCinco={setVagaVinteCinco}
              setVagaCinquenta={setVagaCinquenta}
              setVagaCem={setVagaCem}
              liberado={isPaid}
            />
          ) : categoria === "10 a 25" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={vagaDez}
              setCadastroReserva={setCadastroReserva}
              setVagaUm={setVagaUm}
              setVagaDoisDez={setVagaDoisDez}
              setVagaDez={setVagaDez}
              setVagaVinteCinco={setVagaVinteCinco}
              setVagaCinquenta={setVagaCinquenta}
              setVagaCem={setVagaCem}
              liberado={isPaid}
            />
          ) : categoria === "25 a 50" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={vagaVinteCinco}
              setCadastroReserva={setCadastroReserva}
              setVagaUm={setVagaUm}
              setVagaDoisDez={setVagaDoisDez}
              setVagaDez={setVagaDez}
              setVagaVinteCinco={setVagaVinteCinco}
              setVagaCinquenta={setVagaCinquenta}
              setVagaCem={setVagaCem}
              liberado={isPaid}
            />
          ) : categoria === "50 a 100" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={vagaCinquenta}
              setCadastroReserva={setCadastroReserva}
              setVagaUm={setVagaUm}
              setVagaDoisDez={setVagaDoisDez}
              setVagaDez={setVagaDez}
              setVagaVinteCinco={setVagaVinteCinco}
              setVagaCinquenta={setVagaCinquenta}
              setVagaCem={setVagaCem}
              liberado={isPaid}
            />
          ) : categoria === "mais de 100" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={vagaCem}
              setCadastroReserva={setCadastroReserva}
              setVagaUm={setVagaUm}
              setVagaDoisDez={setVagaDoisDez}
              setVagaDez={setVagaDez}
              setVagaVinteCinco={setVagaVinteCinco}
              setVagaCinquenta={setVagaCinquenta}
              setVagaCem={setVagaCem}
              liberado={isPaid}
            />
          ) : // SALÁRIO
          categoria === "Sem rendimentos" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={semRendimentos}
              setSemRendimentos={setSemRendimentos}
              setSalario1={setSalario1}
              setSalario2={setSalario2}
              setSalario3={setSalario3}
              setSalario4={setSalario4}
              setSalario5={setSalario5}
              setSalario6={setSalario6}
              setSalario7={setSalario7}
              setSalario8={setSalario8}
              setSalario9={setSalario9}
              setSalario10={setSalario10}
              setSalario11={setSalario11}
              setSalario12={setSalario12}
              setSalario13={setSalario13}
              setSalario14={setSalario14}
              setSalario15={setSalario15}
              liberado={isPaid}
            />
          ) : categoria === "Até R$ 1.0000" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={salario1}
              setSemRendimentos={setSemRendimentos}
              setSalario1={setSalario1}
              setSalario2={setSalario2}
              setSalario3={setSalario3}
              setSalario4={setSalario4}
              setSalario5={setSalario5}
              setSalario6={setSalario6}
              setSalario7={setSalario7}
              setSalario8={setSalario8}
              setSalario9={setSalario9}
              setSalario10={setSalario10}
              setSalario11={setSalario11}
              setSalario12={setSalario12}
              setSalario13={setSalario13}
              setSalario14={setSalario14}
              setSalario15={setSalario15}
              liberado={isPaid}
            />
          ) : categoria === "R$ 1.000 a R$ 1.500" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={salario2}
              setSemRendimentos={setSemRendimentos}
              setSalario1={setSalario1}
              setSalario2={setSalario2}
              setSalario3={setSalario3}
              setSalario4={setSalario4}
              setSalario5={setSalario5}
              setSalario6={setSalario6}
              setSalario7={setSalario7}
              setSalario8={setSalario8}
              setSalario9={setSalario9}
              setSalario10={setSalario10}
              setSalario11={setSalario11}
              setSalario12={setSalario12}
              setSalario13={setSalario13}
              setSalario14={setSalario14}
              setSalario15={setSalario15}
              liberado={isPaid}
            />
          ) : categoria === "R$ 1.500 a R$ 2.000" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={salario3}
              setSemRendimentos={setSemRendimentos}
              setSalario1={setSalario1}
              setSalario2={setSalario2}
              setSalario3={setSalario3}
              setSalario4={setSalario4}
              setSalario5={setSalario5}
              setSalario6={setSalario6}
              setSalario7={setSalario7}
              setSalario8={setSalario8}
              setSalario9={setSalario9}
              setSalario10={setSalario10}
              setSalario11={setSalario11}
              setSalario12={setSalario12}
              setSalario13={setSalario13}
              setSalario14={setSalario14}
              setSalario15={setSalario15}
              liberado={isPaid}
            />
          ) : categoria === "R$ 2.000 a R$ 2.500" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={salario4}
              setSemRendimentos={setSemRendimentos}
              setSalario1={setSalario1}
              setSalario2={setSalario2}
              setSalario3={setSalario3}
              setSalario4={setSalario4}
              setSalario5={setSalario5}
              setSalario6={setSalario6}
              setSalario7={setSalario7}
              setSalario8={setSalario8}
              setSalario9={setSalario9}
              setSalario10={setSalario10}
              setSalario11={setSalario11}
              setSalario12={setSalario12}
              setSalario13={setSalario13}
              setSalario14={setSalario14}
              setSalario15={setSalario15}
              liberado={isPaid}
            />
          ) : categoria === "R$ 2.500 a R$ 3.000" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={salario5}
              setSemRendimentos={setSemRendimentos}
              setSalario1={setSalario1}
              setSalario2={setSalario2}
              setSalario3={setSalario3}
              setSalario4={setSalario4}
              setSalario5={setSalario5}
              setSalario6={setSalario6}
              setSalario7={setSalario7}
              setSalario8={setSalario8}
              setSalario9={setSalario9}
              setSalario10={setSalario10}
              setSalario11={setSalario11}
              setSalario12={setSalario12}
              setSalario13={setSalario13}
              setSalario14={setSalario14}
              setSalario15={setSalario15}
              liberado={isPaid}
            />
          ) : categoria === "R$ 3.000 a R$ 4.000" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={salario6}
              setSemRendimentos={setSemRendimentos}
              setSalario1={setSalario1}
              setSalario2={setSalario2}
              setSalario3={setSalario3}
              setSalario4={setSalario4}
              setSalario5={setSalario5}
              setSalario6={setSalario6}
              setSalario7={setSalario7}
              setSalario8={setSalario8}
              setSalario9={setSalario9}
              setSalario10={setSalario10}
              setSalario11={setSalario11}
              setSalario12={setSalario12}
              setSalario13={setSalario13}
              setSalario14={setSalario14}
              setSalario15={setSalario15}
              liberado={isPaid}
            />
          ) : categoria === "R$ 4.000 a R$ 5.000" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={salario7}
              setSemRendimentos={setSemRendimentos}
              setSalario1={setSalario1}
              setSalario2={setSalario2}
              setSalario3={setSalario3}
              setSalario4={setSalario4}
              setSalario5={setSalario5}
              setSalario6={setSalario6}
              setSalario7={setSalario7}
              setSalario8={setSalario8}
              setSalario9={setSalario9}
              setSalario10={setSalario10}
              setSalario11={setSalario11}
              setSalario12={setSalario12}
              setSalario13={setSalario13}
              setSalario14={setSalario14}
              setSalario15={setSalario15}
              liberado={isPaid}
            />
          ) : categoria === "R$ 5.000 a R$ 7.500" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={salario8}
              setSemRendimentos={setSemRendimentos}
              setSalario1={setSalario1}
              setSalario2={setSalario2}
              setSalario3={setSalario3}
              setSalario4={setSalario4}
              setSalario5={setSalario5}
              setSalario6={setSalario6}
              setSalario7={setSalario7}
              setSalario8={setSalario8}
              setSalario9={setSalario9}
              setSalario10={setSalario10}
              setSalario11={setSalario11}
              setSalario12={setSalario12}
              setSalario13={setSalario13}
              setSalario14={setSalario14}
              setSalario15={setSalario15}
              liberado={isPaid}
            />
          ) : categoria === "R$ 7.500 a R$ 10.000" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={salario9}
              setSemRendimentos={setSemRendimentos}
              setSalario1={setSalario1}
              setSalario2={setSalario2}
              setSalario3={setSalario3}
              setSalario4={setSalario4}
              setSalario5={setSalario5}
              setSalario6={setSalario6}
              setSalario7={setSalario7}
              setSalario8={setSalario8}
              setSalario9={setSalario9}
              setSalario10={setSalario10}
              setSalario11={setSalario11}
              setSalario12={setSalario12}
              setSalario13={setSalario13}
              setSalario14={setSalario14}
              setSalario15={setSalario15}
              liberado={isPaid}
            />
          ) : categoria === "R$ 10.000 a R$ 15.000" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={salario10}
              setSemRendimentos={setSemRendimentos}
              setSalario1={setSalario1}
              setSalario2={setSalario2}
              setSalario3={setSalario3}
              setSalario4={setSalario4}
              setSalario5={setSalario5}
              setSalario6={setSalario6}
              setSalario7={setSalario7}
              setSalario8={setSalario8}
              setSalario9={setSalario9}
              setSalario10={setSalario10}
              setSalario11={setSalario11}
              setSalario12={setSalario12}
              setSalario13={setSalario13}
              setSalario14={setSalario14}
              setSalario15={setSalario15}
              liberado={isPaid}
            />
          ) : categoria === "R$ 15.000 a R$ 20.000" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={salario11}
              setSemRendimentos={setSemRendimentos}
              setSalario1={setSalario1}
              setSalario2={setSalario2}
              setSalario3={setSalario3}
              setSalario4={setSalario4}
              setSalario5={setSalario5}
              setSalario6={setSalario6}
              setSalario7={setSalario7}
              setSalario8={setSalario8}
              setSalario9={setSalario9}
              setSalario10={setSalario10}
              setSalario11={setSalario11}
              setSalario12={setSalario12}
              setSalario13={setSalario13}
              setSalario14={setSalario14}
              setSalario15={setSalario15}
              liberado={isPaid}
            />
          ) : categoria === "R$ 20.000 a R$ 30.000" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={salario12}
              setSemRendimentos={setSemRendimentos}
              setSalario1={setSalario1}
              setSalario2={setSalario2}
              setSalario3={setSalario3}
              setSalario4={setSalario4}
              setSalario5={setSalario5}
              setSalario6={setSalario6}
              setSalario7={setSalario7}
              setSalario8={setSalario8}
              setSalario9={setSalario9}
              setSalario10={setSalario10}
              setSalario11={setSalario11}
              setSalario12={setSalario12}
              setSalario13={setSalario13}
              setSalario14={setSalario14}
              setSalario15={setSalario15}
              liberado={isPaid}
            />
          ) : categoria === "R$ 30.000 a R$ 40.000" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={salario13}
              setSemRendimentos={setSemRendimentos}
              setSalario1={setSalario1}
              setSalario2={setSalario2}
              setSalario3={setSalario3}
              setSalario4={setSalario4}
              setSalario5={setSalario5}
              setSalario6={setSalario6}
              setSalario7={setSalario7}
              setSalario8={setSalario8}
              setSalario9={setSalario9}
              setSalario10={setSalario10}
              setSalario11={setSalario11}
              setSalario12={setSalario12}
              setSalario13={setSalario13}
              setSalario14={setSalario14}
              setSalario15={setSalario15}
              liberado={isPaid}
            />
          ) : categoria === "mais de R$ 40.000" ? (
            <FilterCategorySquare
              key={index}
              nome={categoria}
              name={nome}
              checked={salario14}
              setSemRendimentos={setSemRendimentos}
              setSalario1={setSalario1}
              setSalario2={setSalario2}
              setSalario3={setSalario3}
              setSalario4={setSalario4}
              setSalario5={setSalario5}
              setSalario6={setSalario6}
              setSalario7={setSalario7}
              setSalario8={setSalario8}
              setSalario9={setSalario9}
              setSalario10={setSalario10}
              setSalario11={setSalario11}
              setSalario12={setSalario12}
              setSalario13={setSalario13}
              setSalario14={setSalario14}
              setSalario15={setSalario15}
              liberado={isPaid}
            />
          ) : null;
        })}
      </div>
    </StyledFilterSubMenu>
  );
}

export default FilterSubMenu;
