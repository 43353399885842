import styled from "styled-components";

export const StyledCardStiky = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  cursor: pointer;

  h2 {
    font-size: 2rem;
    font-weight: normal;
  }
  p {
    font-size: 1.2rem;
  }
  .balaoCardSticky {
    position: absolute;
    top: -5%;
    left: 115%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16rem;
    // width: fit-content;
    // height: 3rem;
    font-size: 1.6rem;
    background-color: var(--Grey-100);
    border: 1px solid var(--Grey-200);
    border-radius: 0.8rem;
    padding: 0.8rem 0.4rem;
    z-index: 99;
  }
`;
