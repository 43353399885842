export const estados = [
  "Acre",
  "Alagoas",
  "Amapá",
  "Amazonas",
  "Bahia",
  "Ceará",
  "Distrito Federal",
  "Espírito Santo",
  "Goiás",
  "Maranhão",
  "Mato Grosso",
  "Mato Grosso do Sul",
  "Minas Gerais",
  "Paraíba",
  "Pará",
  "Paraná",
  "Pernambuco",
  "Piauí",
  "Rio Grande do Norte",
  "Rio Grande do Sul",
  "Rio de Janeiro",
  "Rondônia",
  "Roraima",
  "Santa Catarina",
  "São Paulo",
  "Sergipe",
  "Tocantins",
  "Diversos",
];
export const nivelEducacional = [
  "Fundamental",
  "Médio",
  "Superior",
  "Mestrado",
  "Doutorado",
];
export const faseConcurso = [
  "Edital publicado",
  "Inscrições abertas",
  "Inscrições encerradas",
];
export const vagas = [
  "Cadastro de Reserva",
  "1",
  "2 a 10",
  "10 a 25",
  "25 a 50",
  "50 a 100",
  "mais de 100",
];
export const salario = [
  "Sem rendimentos",
  "Até R$ 1.0000",
  "R$ 1.000 a R$ 1.500",
  "R$ 1.500 a R$ 2.000",
  "R$ 2.000 a R$ 2.500",
  "R$ 2.500 a R$ 3.000",
  "R$ 3.000 a R$ 4.000",
  "R$ 4.000 a R$ 5.000",
  "R$ 5.000 a R$ 7.500",
  "R$ 7.500 a R$ 10.000",
  "R$ 10.000 a R$ 15.000",
  "R$ 15.000 a R$ 20.000",
  "R$ 20.000 a R$ 30.000",
  "R$ 30.000 a R$ 40.000",
  "mais de R$ 40.000",
];
