import styled from "styled-components";

export const StyledLoader = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  // border: 2px solid red;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 3rem;

  h1 {
    color: #1629a8;
    font-size: 5rem;
    font-family: "Work Sans", sans-serif;
    font-weight: 400;
    letter-spacing: 0.1rem;
  }
  img {
    width: 4.8rem;
  }
`;
