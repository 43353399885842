import { React, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import search from "../assets/search.png";
import { StyledSearchBar } from "../styles/SearchBar";

function SearchBar({ design, pagina, setSearchReset, searchReset }) {
  const location = useLocation();
  const navigate = useNavigate();

  const [userInput, setUserInput] = useState("");
  const [placeholder, setPlaceholder] = useState("Digite um cargo público");
  const [border, setBorder] = useState("#A0A5BD");
  const [buttonBorder, setButtonBorder] = useState("#1B2CC1");
  let encodedSearchTerm = "";

  function capturandoTermoPesquisado(termo) {
    ReactGA.event({
      category:
        pagina === "offices" ? "Search in offices" : "Search in contests",
      action: "search_term",
      label: termo,
    });
  }

  const SearchFunction = (e) => {
    e.preventDefault();
    if (userInput === "") {
      setBorder("red");
      setPlaceholder("Não é possível pesquisar com o campo vazio");
      setTimeout(() => {
        setBorder("#A0A5BD");
        setPlaceholder("Digite um cargo público");
      }, 1000);
    } else {
      if (pagina === "offices") {
        capturandoTermoPesquisado(userInput);
        encodedSearchTerm = encodeURIComponent(userInput);
        navigate(`/cargos/${encodedSearchTerm}`);
      } else {
        capturandoTermoPesquisado(userInput);
        encodedSearchTerm = encodeURIComponent(userInput);
        navigate(`/concursos/${encodedSearchTerm}`);
      }
      setSearchReset(!searchReset);
    }
  };

  useEffect(() => {
    let paramsSearch = location.pathname;
    if (paramsSearch) {
      paramsSearch = paramsSearch.split("/");
      if (
        decodeURIComponent(
          paramsSearch[paramsSearch.length - 1] !== "cargos"
        ) &&
        decodeURIComponent(
          paramsSearch[paramsSearch.length - 1] !== "concursos"
        )
      ) {
        setUserInput(decodeURIComponent(paramsSearch[paramsSearch.length - 1]));
      }

      if (
        decodeURIComponent(paramsSearch[paramsSearch.length - 1]) === "cargos"
      ) {
        setUserInput("");
      }
      if (
        decodeURIComponent(paramsSearch[paramsSearch.length - 1]) ===
        "concursos"
      ) {
        setUserInput("");
      }
    }
  }, [location]);

  return (
    <StyledSearchBar
      className={`SearchBar ${design} `}
      onSubmit={SearchFunction}
    >
      <div>
        <input
          value={userInput}
          style={{ borderColor: border }}
          type="text"
          onChange={(e) => setUserInput(e.target.value)}
          placeholder={
            pagina === "offices" ? "Pesquise um cargo" : "Pesquise um concurso"
          }
        />

        <button style={{ borderColor: buttonBorder }} type="submit">
          <img src={search} alt="ícone de pesquisa" />
        </button>
      </div>
    </StyledSearchBar>
  );
}

export default SearchBar;
