import { React, useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { connect } from "react-redux";
import { reset_password_confirm } from "../actions/auth";
import { StyledResetConfirm } from "../styles/ResetConfirm";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const schema = Yup.object().shape({
  new_password: Yup.string()
    .min(6, "* A nova senha deve ter no mínimo 6 caracteres")
    .matches(
      /(?=.*[A-Z])/,
      "* A nova senha deve conter pelo menos uma letra maiúscula"
    )
    .matches(
      /(?=.*[a-z])/,
      "* A nova senha deve conter pelo menos uma letra minúscula"
    )
    .matches(/(?=.*[0-9])/, "* A nova senha deve conter pelo menos um número")
    .matches(
      /(?=.*[@$!%*?&])/,
      "* A nova senha deve conter pelo menos um caractere especial"
    )
    .matches(/^(?!.*\b(?:123456|password|qwerty)\b).*$/, "* Senha muito fraca")
    .required("* Senha é obrigatória"),
  re_new_password: Yup.string()
    .oneOf([Yup.ref("new_password"), null], "* As senhas devem ser iguais.")
    .required("* As senhas devem ser iguais."),
});

function ResetConfirm({ reset_password_confirm }) {
  const navigate = useNavigate();
  const [requestSent, setRequestSent] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      new_password: "",
      re_new_password: "",
    },
  });

  useEffect(() => {
    if (requestSent) {
      const timer = setTimeout(() => {
        navigate("/login");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [requestSent, navigate]);

  const { uid, token } = useParams();

  const onSubmit = (data) => {
    const { new_password, re_new_password } = data;

    reset_password_confirm(uid, token, new_password, re_new_password);
    setRequestSent(true);
  };

  return (
    <StyledResetConfirm>
      {requestSent ? (
        <>
          <div className="oldResetConfirm" style={{ marginBottom: "4.8rem" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="96"
              height="96"
              viewBox="0 0 96 96"
              fill="none"
            >
              <g clip-path="url(#clip0_2463_218)">
                <path
                  d="M72 48C58.744 48 48 58.744 48 72C48 85.256 58.744 96 72 96C85.256 96 96 85.256 96 72C96 58.744 85.256 48 72 48ZM86.732 70.848L75.92 81.304C74.112 83.088 71.712 83.988 69.308 83.988C66.904 83.988 64.496 83.088 62.656 81.292L57.24 75.964C55.66 74.416 55.64 71.884 57.184 70.308C58.728 68.728 61.26 68.704 62.84 70.252L68.256 75.576C68.832 76.144 69.776 76.132 70.344 75.568L81.196 65.072C82.784 63.548 85.32 63.592 86.852 65.188C88.38 66.78 88.328 69.312 86.736 70.844L86.732 70.848ZM40 72C37.792 72 36 70.212 36 68V60C36 57.788 37.792 56 40 56C41.42 56 42.608 56.784 43.32 57.896C48.544 47.312 59.42 40 72 40C73.408 40 74.784 40.124 76.148 40.3C74.064 37.444 71.276 35.132 68 33.696V28C68 12.56 55.44 0 40 0C24.56 0 12 12.56 12 28V33.696C4.948 36.792 0 43.82 0 52V76C0 87.028 8.972 96 20 96H50.904C44.236 90.132 40 81.56 40 72ZM20 28C20 16.972 28.972 8 40 8C51.028 8 60 16.972 60 28V32H20V28Z"
                  fill="#5FCE1D"
                />
              </g>
              <defs>
                <clipPath id="clip0_2463_218">
                  <rect width="96" height="96" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <div className="flexTittleText">
              <h1>Senha redefinida com sucesso</h1>
              <p>Em 5 segundos você será redirecionado para a tela de login.</p>
            </div>
          </div>
          <div className="redirectWarning">
            <p>
              <Link to="/login">Clique aqui</Link> caso você não seja
              redirecionado de forma automática.
            </p>
          </div>
        </>
      ) : (
        <div className="oldResetConfirm">
          <h1>Atualize sua senha</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label htmlFor="new_password">Nova senha</label>
              <Controller
                name="new_password"
                control={control}
                render={({ field }) => (
                  <input
                    className={
                      errors.new_password
                        ? "form-control errorForm"
                        : "form-control"
                    }
                    type="password"
                    placeholder="••••••••"
                    {...field}
                    id="new_password"
                  />
                )}
              />
              {errors.new_password && (
                <p className="error">{errors.new_password.message}</p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="new_password">Confirmar senha</label>
              <Controller
                name="re_new_password"
                control={control}
                render={({ field }) => (
                  <input
                    className={
                      errors.re_new_password
                        ? "form-control errorForm"
                        : "form-control"
                    }
                    type="password"
                    placeholder="••••••••"
                    {...field}
                    id="re_new_password"
                  />
                )}
              />
              {errors.re_new_password && (
                <p className="error">{errors.re_new_password.message}</p>
              )}
            </div>
            <button type="submit">Definir a nova senha</button>
          </form>
        </div>
      )}
    </StyledResetConfirm>
  );
}

export default connect(null, { reset_password_confirm })(ResetConfirm);
