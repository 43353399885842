import { StyledStatisticCard } from "../styles/StatisticCard.js";

function StatisticCard({ paddingTop, percent, tittle, subTittle }) {
  return (
    <StyledStatisticCard
      className="StatisticCard"
      style={{ paddingTop: paddingTop }}
    >
      <h3>{`${percent}`}</h3>
      <h4>{tittle}</h4>
      <p>{subTittle}</p>
    </StyledStatisticCard>
  );
}

export default StatisticCard;
