import { React, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../actions/auth";
import { StyledLogin } from "../styles/Login";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import exclamation from "../assets/exclamation.svg";

const schema = Yup.object().shape({
  email: Yup.string()
    .email("* É necessário inserir um e-mail válido.")
    .required("* Email é obrigatório"),
  password: Yup.string().required(
    "* É necessário inserir uma senha para logar."
  ),
});

function Login({ login, isAuthenticated, urlAtual, tipoDePlano }) {
  document.title = `Login - Alô Concursos`;
  const URL = process.env.REACT_APP_API_URL;
  let urlWithoutParameters = urlAtual.split("?")[0];

  if (urlWithoutParameters.split("/").length > 2) {
    urlWithoutParameters = `/${urlWithoutParameters.split("/")[1]}`;
  }

  if (urlWithoutParameters === "/") {
    urlWithoutParameters = "";
  }
  const redirectURL = `${URL}${urlWithoutParameters}`;
  const [errorMessage, setErrorMessage] = useState("");

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (data) => {
    if (!data.email) {
      setErrorMessage("* Por favor, insira seu e-mail.");
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
      setErrorMessage("* Por favor, insira um e-mail válido.");
    } else {
      setErrorMessage("");
      const result = await login(data.email, data.password);
      if (!result.success) {
        setErrorMessage("* O seu e-mail ou a sua senha não estão corretos");
      }
    }
  };

  if (isAuthenticated) {
    if (tipoDePlano) {
      payment(tipoDePlano);
    } else {
      return <Navigate to={urlAtual} />;
    }
  }

  const continueWithGoogle = async () => {
    try {
      const res = await axios.get(
        `${URL}/auth/o/google-oauth2/?redirect_uri=${redirectURL}`
      );
      window.location.replace(res.data.authorization_url);
    } catch (err) {
      console.log(err);
    }
  };

  const continueWithFacebook = async () => {
    try {
      const res = await axios.get(
        `${URL}/auth/o/facebook/?redirect_uri=${URL}`
      );

      window.location.replace(res.data.authorization_url);
    } catch (err) {}
  };

  async function payment(tipoDePlano) {
    let payment;
    const token = localStorage.getItem("access");

    if (tipoDePlano === "monthly") {
      payment = "/api-stripe/create-checkout-session-monthly";
    } else {
      payment = "/api-stripe/create-checkout-session-yearly";
    }

    fetch(`${URL}${payment}`, {
      method: "POST",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json(); // Receber a resposta como JSON
      })
      .then((data) => {
        if (data.url) {
          // Redireciona para a URL de checkout do Stripe
          window.location.href = data.url;
        } else {
          throw new Error("No URL returned from server");
        }
      })
      .catch((error) => console.error("Error:", error));
  }

  return (
    <StyledLogin>
      <h2>Acesse a sua conta</h2>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group mb8">
          {errorMessage && (
            <div className="errorMessage">
              <img src={exclamation} alt="exclamation" />
              <p>{errorMessage}</p>
            </div>
          )}
          <label htmlFor="emailL">E-mail</label>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <input
                className={
                  errors.email ? "form-control errorForm" : "form-control"
                }
                type="email"
                placeholder="Insira seu e-mail"
                id="emailL"
                {...field}
              />
            )}
          />
          {errors.email && (
            <div className="invalid-feedback">{errors.email.message}</div>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="passwordL">Senha</label>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <input
                className={
                  errors.password ? "form-control errorForm" : "form-control"
                }
                type="password"
                placeholder="••••••••"
                id="passwordL"
                {...field}
              />
            )}
          />
          {errors.password && (
            <div className="invalid-feedback">{errors.password.message}</div>
          )}
        </div>

        <button type="submit">Fazer login</button>
      </form>
      <div className="flexGoogleFacebook">
        <button onClick={continueWithGoogle}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <g clipPath="url(#clip0_2311_64)">
              <path
                d="M24.266 12.2765C24.266 11.4608 24.1999 10.6406 24.0588 9.83813H12.74V14.4591H19.2217C18.9528 15.9495 18.0885 17.2679 16.823 18.1056V21.104H20.69C22.9608 19.014 24.266 15.9274 24.266 12.2765Z"
                fill="#4285F4"
              />
              <path
                d="M12.74 24.0008C15.9764 24.0008 18.7058 22.9382 20.6944 21.1039L16.8274 18.1055C15.7516 18.8375 14.3626 19.252 12.7444 19.252C9.61376 19.252 6.95934 17.1399 6.00693 14.3003H2.01648V17.3912C4.05359 21.4434 8.20278 24.0008 12.74 24.0008Z"
                fill="#34A853"
              />
              <path
                d="M6.00253 14.3002C5.49987 12.8099 5.49987 11.196 6.00253 9.70569V6.61475H2.01649C0.31449 10.0055 0.31449 14.0004 2.01649 17.3912L6.00253 14.3002Z"
                fill="#FBBC04"
              />
              <path
                d="M12.74 4.74966C14.4508 4.7232 16.1043 5.36697 17.3433 6.54867L20.7694 3.12262C18.6 1.0855 15.7207 -0.034466 12.74 0.000808666C8.20277 0.000808666 4.05359 2.55822 2.01648 6.61481L6.00252 9.70575C6.95052 6.86173 9.60935 4.74966 12.74 4.74966Z"
                fill="#EA4335"
              />
            </g>
            <defs>
              <clipPath id="clip0_2311_64">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(0.5)"
                />
              </clipPath>
            </defs>
          </svg>
          <p>Acesse com o Google</p>
        </button>
        <button onClick={continueWithFacebook}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <g clipPath="url(#clip0_2311_71)">
              <path
                d="M24.5 12C24.5 5.37258 19.1274 0 12.5 0C5.87258 0 0.5 5.37258 0.5 12C0.5 17.9895 4.8882 22.954 10.625 23.8542V15.4688H7.57812V12H10.625V9.35625C10.625 6.34875 12.4166 4.6875 15.1576 4.6875C16.4701 4.6875 17.8438 4.92188 17.8438 4.92188V7.875H16.3306C14.84 7.875 14.375 8.80008 14.375 9.75V12H17.7031L17.1711 15.4688H14.375V23.8542C20.1118 22.954 24.5 17.9895 24.5 12Z"
                fill="#1877F2"
              />
              <path
                d="M17.1711 15.4688L17.7031 12H14.375V9.75C14.375 8.80102 14.84 7.875 16.3306 7.875H17.8438V4.92188C17.8438 4.92188 16.4705 4.6875 15.1576 4.6875C12.4166 4.6875 10.625 6.34875 10.625 9.35625V12H7.57812V15.4688H10.625V23.8542C11.8674 24.0486 13.1326 24.0486 14.375 23.8542V15.4688H17.1711Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_2311_71">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(0.5)"
                />
              </clipPath>
            </defs>
          </svg>
          <p>Acesse com o Facebook</p>
        </button>
      </div>
      <div className="flexRegister">
        <p className="Register">
          Não possui uma conta? <Link to="/cadastro">Registre-se</Link>
        </p>
        <p className="Register">
          Esqueceu sua senha? <Link to="/resetar-senha">Resetar senha</Link>
        </p>
      </div>
    </StyledLogin>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Login);
