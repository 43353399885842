import { StyledCardPayment } from "../styles/CardPayment";
import checkCircle from "../assets/checkCircle.svg";
import lockCircle from "../assets/lockCircle.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect } from "react";
import QueryString from "query-string";

function CardPayment({
  tittle,
  subtittle,
  middleTittle,
  advantages,
  payment,
  button,
  buttonCss,
  isAuthenticated,
  setTipoDePlano,
  planType,
}) {
  const URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("access");

  const location = useLocation();

  useEffect(() => {
    const values = QueryString.parse(location.search);

    if (values.success) {
      console.log("Order placed! You will receive an email confirmation.");
    }

    if (values.canceled) {
      console.log(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [location.search]);

  function handleRedirect(planType) {
    setTipoDePlano(planType);
    navigate("/login");
  }

  async function handleSubmit(e) {
    e.preventDefault();

    fetch(`${URL}${payment}`, {
      method: "POST",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json(); // Receber a resposta como JSON
      })
      .then((data) => {
        if (data.url) {
          // Redireciona para a URL de checkout do Stripe
          window.location.href = data.url;
        } else {
          throw new Error("No URL returned from server");
        }
      })
      .catch((error) => console.error("Error:", error));
  }

  const navigate = useNavigate();
  return (
    <StyledCardPayment className="CardPayment" onSubmit={handleSubmit}>
      <div className="flexTittlePlan">
        <h1>{tittle}</h1>
        <p>{subtittle}</p>
      </div>
      <div>
        {middleTittle.length > 6 ? (
          <div className="flexMiddleTittle">
            <h3>{middleTittle.split(" ")[0]}</h3>
            <h2>{middleTittle.split(" ")[1]}</h2>
            <h5>{middleTittle.split(" ")[2]}</h5>
          </div>
        ) : (
          <h2 className="middleTittle">{middleTittle}</h2>
        )}
      </div>
      <div className="flexAdvantages">
        {advantages.map((advantage) => {
          return (
            <div key={advantage.id} className="advantages">
              {advantage.status === "active" ? (
                <img src={checkCircle} alt="Ativo" />
              ) : (
                <img src={lockCircle} alt="Inativo" />
              )}
              <p
                className={advantage.status === "active" ? "active" : "disable"}
              >
                {advantage.text}
              </p>
            </div>
          );
        })}
      </div>
      {button === "semBotao" ? null : isAuthenticated ? (
        <button
          style={buttonCss ? buttonCss : {}}
          type="submit"
          className="button2"
        >
          <p>{button}</p>
        </button>
      ) : (
        <button
          style={buttonCss ? buttonCss : {}}
          onClick={() => handleRedirect(planType)}
          className="button2"
        >
          <p>{button}</p>
        </button>
      )}
    </StyledCardPayment>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(CardPayment);
