import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { connect } from "react-redux";
import { verify } from "../actions/auth";
import { StyledActivate } from "../styles/Activate";

const Activate = ({ verify }) => {
  const navigate = useNavigate();
  const [verified, setVerified] = useState(false);
  const { uid, token } = useParams();

  const verify_account = (e) => {
    verify(uid, token);
    setVerified(true);
  };

  useEffect(() => {
    if (verified) {
      const timer = setTimeout(() => {
        navigate("/login");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [verified, navigate]);

  return (
    <StyledActivate>
      {verified ? (
        <>
          <div className="oldActivate" style={{ marginBottom: "4.8rem" }}>
            <svg
              className="checkmark"
              xmlns="http://www.w3.org/2000/svg"
              width="96"
              height="96"
              viewBox="0 0 96 96"
              fill="none"
            >
              <g clip-path="url(#clip0_2463_183)">
                <path
                  d="M48 0C21.532 0 0 21.532 0 48C0 74.468 21.532 96 48 96C74.468 96 96 74.468 96 48C96 21.532 74.468 0 48 0ZM72.8 42.048L55.096 59.428C51.964 62.5 47.932 64.032 43.896 64.032C39.904 64.032 35.912 62.528 32.792 59.516L25.196 52.048C23.62 50.5 23.6 47.968 25.148 46.392C26.692 44.812 29.232 44.792 30.804 46.344L38.376 53.788C41.48 56.788 46.38 56.772 49.5 53.716L67.2 36.34C68.772 34.788 71.296 34.816 72.856 36.392C74.404 37.968 74.38 40.5 72.8 42.048Z"
                  fill="#5FCE1D"
                />
              </g>
              <defs>
                <clipPath id="clip0_2463_183">
                  <rect width="96" height="96" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <div className="flexTittleText">
              <h1>Seu e-mail foi verificado com sucesso</h1>
              <p>
                Obrigado por fazer parte da Alô Concursos! Em 5 segundos você
                será redirecionado para a página de login. <br />
                <br /> Você também receberá um e-mail informando que a
                verificação de e-mail aconteceu com sucesso.
              </p>
            </div>
          </div>
          <div className="redirectWarning">
            <p>
              <Link to="/login">Clique aqui</Link> caso você não seja
              redirecionado de forma automática.
            </p>
          </div>
        </>
      ) : (
        <div className="oldActivate">
          <div className="flexTittle">
            <h1>Verificar e-mail</h1>
            <p>Cliquei no botão abaixo para confirmar seu e-mail.</p>
          </div>
          <button onClick={verify_account} type="button">
            Confirmar e-mail
          </button>
        </div>
      )}
    </StyledActivate>
  );
};

export default connect(null, { verify })(Activate);
