import React from "react";

function BoxUserAccount({ children, tittle }) {
  return (
    <div className="info">
      <h5>{tittle}</h5>
      <div className="flexInfo">
        <div className="infoBox">{children}</div>
      </div>
    </div>
  );
}

export default BoxUserAccount;
