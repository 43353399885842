import styled from "styled-components";

export const StyledHomepage = styled.main`
  width: 90%;
  margin: 25.6rem auto 0 auto;
  min-height: 55rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  justify-content: center;
  align-items: center;

  .Logo {
    width: 29.6rem;
  }

  .flexHome {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    width: 51.2rem;
    min-width: 51.2rem; // aqui tem que seguir a largura da barra
    margin-bottom: 16rem;
  }
  .Confira {
    font-size: 3.2rem;
    font-family: "Work Sans", sans-serif;
    font-weight: normal;
    margin-bottom: 3.2rem;
  }
  i {
    margin-top: 0.8rem;
    font-size: 2rem;
    font-family: "Work Sans", sans-serif;
    margin-bottom: 3.2rem;
  }
  .flexInputRadioHome {
    margin-top: 0.8rem;
    display: flex;
    width: 100%;
    font-size: 2rem;
    font-family: "Work Sans", sans-serif;
    justify-content: space-evenly;
    gap: 1.8rem;
    div {
      display: flex;
      align-items: center;
      gap: 0.6rem;
      input {
        cursor: pointer;
      }
    }
  }
  .slideLastContests {
    width: 90%;
    position: relative;
    left: calc(50% - 7rem);
    transform: translateX(-50%);
    margin-bottom: 8rem;

    .buttonLeft {
      background-color: transparent;
      border: none;
      height: 100%;
      padding: 0 2rem;
      font-size: 5rem;
      position: absolute;
      left: -8rem;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
    .buttonRight {
      background-color: transparent;
      border: none;
      height: 100%;
      padding: 0 2rem;
      font-size: 5rem;
      position: absolute;
      right: -8rem;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
    .slideLastContestsProducts {
      width: 153rem;
      display: flex;
      gap: 4rem;
      overflow-x: hidden;
      scroll-behavior: smooth;
    }
  }

  .slideContainer {
    width: 100%;
    position: relative;
    margin-bottom: 8rem;

    .um {
      left: 99.5%;
    }
    .dois {
      right: 99.5%;
    }

    .swiper-button-prev {
      top: 0 !important;
      margin-top: 0 !important;
      height: 100% !important;
      left: 0 !important;
      background-color: white !important;
      width: 5rem !important;
      z-index: 10 !important;
    }
    .swiper-button-next {
      top: 0 !important;
      margin-top: 0 !important;
      height: 100% !important;
      right: 0 !important;
      width: 5rem !important;
      background-color: white !important;
      z-index: 10 !important;
    }
    .swiper {
      padding: 0 5rem !important;
    }

    .tampaBug {
      position: absolute;
      height: 100%;
      z-index: 8;
      background-color: white;
      width: 3rem;
    }
  }
`;
