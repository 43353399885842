import { StyledBandeira } from "../styles/Bandeira";

import PR from "../assets/Bandeiras/PR.svg";
import AL from "../assets/Bandeiras/AL.svg";
import PA from "../assets/Bandeiras/PA.svg";
import AP from "../assets/Bandeiras/AP.svg";
import SE from "../assets/Bandeiras/SE.svg";
import RN from "../assets/Bandeiras/RN.svg";
import SC from "../assets/Bandeiras/SC.svg";
import RO from "../assets/Bandeiras/RO.svg";
import TO from "../assets/Bandeiras/TO.svg";
import MA from "../assets/Bandeiras/MA.svg";
import PI from "../assets/Bandeiras/PI.svg";
import RS from "../assets/Bandeiras/RS.svg";
import MG from "../assets/Bandeiras/MG.svg";
import SP from "../assets/Bandeiras/SP.svg";
import AC from "../assets/Bandeiras/AC.svg";
import PB from "../assets/Bandeiras/PB.svg";
import RJ from "../assets/Bandeiras/RJ.svg";
import MS from "../assets/Bandeiras/MS.svg";
import RR from "../assets/Bandeiras/RR.svg";
import GO from "../assets/Bandeiras/GO.svg";
import CE from "../assets/Bandeiras/CE.svg";
import ES from "../assets/Bandeiras/ES.svg";
import PE from "../assets/Bandeiras/PE.svg";
import BA from "../assets/Bandeiras/BA.svg";
import DF from "../assets/Bandeiras/DF.svg";
import MT from "../assets/Bandeiras/MT.svg";
import AM from "../assets/Bandeiras/AM.svg";
import BR from "../assets/Bandeiras/BR.svg";

const bandeiras = {
  PR: PR,
  AL: AL,
  PA: PA,
  AP: AP,
  SE: SE,
  RN: RN,
  SC: SC,
  RO: RO,
  TO: TO,
  MA: MA,
  PI: PI,
  RS: RS,
  MG: MG,
  SP: SP,
  AC: AC,
  PB: PB,
  RJ: RJ,
  MS: MS,
  RR: RR,
  GO: GO,
  CE: CE,
  ES: ES,
  PE: PE,
  BA: BA,
  DF: DF,
  MT: MT,
  AM: AM,
  os: BR,
};

function Bandeira({ nomeDoEstado }) {
  let texto = nomeDoEstado;
  let ultimas_letras = texto.slice(-2);

  const renderizaoImagem = bandeiras[ultimas_letras] || null;

  return (
    <StyledBandeira
      className="Bandeira"
      src={renderizaoImagem ? renderizaoImagem : null}
      alt={nomeDoEstado}
    />
  );
}

export default Bandeira;
