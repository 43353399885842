import React from "react";
import { StyledInnerCardInfo } from "../styles/InnercardInfo";

function InnercardInfo({ tittle, subTittle, css }) {
  return (
    <StyledInnerCardInfo className={css}>
      <h5>{tittle}</h5>
      <p>{subTittle}</p>
    </StyledInnerCardInfo>
  );
}

export default InnercardInfo;
