import { useEffect } from "react";
import { StyledPayment } from "../styles/Payment";
import CardPayment from "../components/CardPayment";
import { Basic, Premium, PremiumPlus } from "../tools/planos";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";

function Payment({
  setUrlAtual,
  setTipoDePlano,
  isAuthenticated,
  tipoDePlano,
}) {
  document.title = `Premium - Alô Concursos`;
  const URL = process.env.REACT_APP_API_URL;
  const location = useLocation();
  // Lógica pra capturar a página antes do login
  useEffect(() => {
    setUrlAtual(location.pathname);
  }, []);

  async function payment(tipoDePlano) {
    let payment;
    const token = localStorage.getItem("access");

    if (tipoDePlano === "monthly") {
      payment = "/api-stripe/create-checkout-session-monthly";
    } else {
      payment = "/api-stripe/create-checkout-session-yearly";
    }

    fetch(`${URL}${payment}`, {
      method: "POST",
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json(); // Receber a resposta como JSON
      })
      .then((data) => {
        if (data.url) {
          // Redireciona para a URL de checkout do Stripe
          window.location.href = data.url;
        } else {
          throw new Error("No URL returned from server");
        }
      })
      .catch((error) => console.error("Error:", error));
  }

  useEffect(() => {
    const savedPlano = localStorage.getItem("tipoDePlano"); // Recupera o plano do localStorage
    console.log(savedPlano);
    // if (savedPlano) {
    //   if (isAuthenticated) {
    //     console.log(savedPlano);
    //     payment(savedPlano);
    //   }
    // }
  }, []);

  return (
    <StyledPayment className="PaymentPage">
      <section className="PaymentMenu">
        <div className="flexTittle">
          <h1>Não perca nenhuma oportunidade!</h1>
          <p>Assine agora e tenha acesso exclusivo.</p>
        </div>
        <div className="flexCardPayment">
          <CardPayment
            setTipoDePlano={setTipoDePlano}
            tittle={"Basic"}
            subtittle={"Não precisa de cartão de crédito"}
            middleTittle={"Grátis"}
            advantages={Basic}
            button={"semBotao"}
          />
          <CardPayment
            setTipoDePlano={setTipoDePlano}
            planType="monthly"
            tittle={"Premium"}
            subtittle={"Pagamento mensal"}
            middleTittle={"R$ 7 ,90/mês"}
            advantages={Premium}
            payment={`/api-stripe/create-checkout-session-monthly`}
            button={"Assinar Premium"}
            buttonCss={{ width: "20.3rem" }}
          />
          <CardPayment
            setTipoDePlano={setTipoDePlano}
            planType="yearly"
            tittle={"Premium Plus"}
            subtittle={"Pagamento anual"}
            middleTittle={"R$ 5 ,90/mês"}
            advantages={PremiumPlus}
            payment={`/api-stripe/create-checkout-session-yearly`}
            button={"Assinar Premium Plus"}
            buttonCss={{
              width: "24.6rem",
              backgroundColor: "#1B2CC1",
              color: "#F0FDF4",
            }}
          />
        </div>
      </section>
    </StyledPayment>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(Payment);
