import React from "react";
import { StyledUserAccountConta } from "../styles/UserAccountConta.js";
import BoxUserAccount from "./BoxUserAccount.jsx";
import { Link } from "react-router-dom";

function UserAccountConta() {
  return (
    <StyledUserAccountConta>
      <h1>Conta</h1>
      <BoxUserAccount tittle={"Informações pessoais"}>
        <div>
          <span>Nome:</span>
          <p>Ana Beatriz</p>
        </div>
        <div>
          <span>Sobrenome:</span>
          <p>Silveira</p>
        </div>
        <div>
          <span>E-mail:</span>
          <p>anabeatriz@gmail.com</p>
        </div>
      </BoxUserAccount>
      <BoxUserAccount tittle={"Segurança"}>
        <Link to="#">Redefinir senha</Link>
      </BoxUserAccount>
      <BoxUserAccount tittle={"Termos e privacidade"}>
        <Link to="#">Termos de uso</Link>
        <Link to="#">Política de Uso dos dados</Link>
      </BoxUserAccount>
    </StyledUserAccountConta>
  );
}

export default UserAccountConta;
