import AboutCards from "../UI/AboutCards";
import { StyledAbout } from "../styles/About";
import facilidade from "../assets/facilidade.png";
import rapidez from "../assets/rapidez.png";
import ampla_cobertura from "../assets/ampla_cobertura.png";
import historia from "../assets/historia.png";
import linha from "../assets/linha.png";
import TutorialBox from "../UI/TutorialBox";
import passo1 from "../assets/passo1.png";
import passo2 from "../assets/passo2.png";
import passo3 from "../assets/passo3.png";
import Statistics from "../assets/Statistics.jpg";
import StatisticCard from "../UI/StatisticCard";
import Faq from "../UI/Faq";
import formemail from "../assets/formemail.png";
import mail from "../assets/mail.png";
import ReactGA from "react-ga4";
import { useState, useEffect } from "react";
import { conversaoData, convertMoney } from "../tools/functions";
import background_hero_features from "../assets/background_hero_features.png";
import { useLocation } from "react-router-dom";

ReactGA.initialize("G-9EJ6PS0PBE");

function About({ setUrlAtual }) {
  const [renderizacao, setRenderizacao] = useState(null);

  document.title = "Sobre - Alô Concursos";

  // Variáveis para consumo da API
  const Token = process.env.REACT_APP_TOKEN;
  const URL = process.env.REACT_APP_API_URL;
  const location = useLocation();
  // Lógica pra capturar a página antes do login
  useEffect(() => {
    setUrlAtual(location.pathname);
  }, []);

  let queryForApi = `${URL}/analytics/analytics/`;

  async function requisicao(apiLink) {
    fetch(apiLink, {
      headers: {
        Authorization: `Token ${Token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        setRenderizacao(response);
      });
  }

  useEffect(() => {
    requisicao(queryForApi);
  }, []);

  //   function formSubmit(e) {
  //     e.preventDefault();
  //     alert("Formulário enviado com sucesso");
  //   }
  return (
    <StyledAbout className="About">
      <section
        className="firstSection"
        style={{ backgroundImage: `url(${background_hero_features})` }}
      >
        <div className="tittleAndSubtittle">
          <div>
            <h1 className="tittle">Sobre a Alô Concursos</h1>
            <img src={linha} alt="linha" />
          </div>
          <p> Todo concurso que você imaginar em um só lugar!</p>
        </div>
        <div className="infoCards">
          <AboutCards
            image={facilidade}
            tittle={"Facilidade para encontrar o que você procura"}
            subTittle={
              "A Alô concursos usa uma moderna tecnologia de mecanismo de busca para garantir que você encontre de forma rápida e prática a sua vaga no serviço público."
            }
          />
          <AboutCards
            image={rapidez}
            tittle={"Rapidez na atualização dos concursos"}
            subTittle={
              "Nossa equipe trabalha diariamente, com auxílio da tecnologia, para garantir que o site esteja sempre atualizado com os últimos concursos abertos no Brasil."
            }
          />
          <AboutCards
            image={ampla_cobertura}
            tittle={"Ampla cobertura dos concursos disponíveis"}
            subTittle={
              "Nosso objetivo é ser o site com a maior cobertura de concursos do Brasil. Não importa o que você procura e onde você deseja trabalhar. Se o concurso é público, ele será publicado na Alô Concursos!"
            }
          />
        </div>
        <div className="ourHistory">
          <h2>Um pouco da nossa história</h2>
          <p>
            A Alô Concursos é uma iniciativa de trabalhadores na área de
            tecnololgia cansados das experiências deficientes oferecidas pelas
            soluções existentes para informações de concursos públicos.
            Frequentemente, em nossas buscas por informações sobre novos
            certames, nos deparávamos com designs desagradáveis, cobertura
            incompleta e usabilidade confusa.
            <br />
            <br />
            Combinando nossas habilidades e determinação, fundamos esta empresa
            com um propósito claro: oferecer uma plataforma que transcenda todas
            essas limitações. Assim nasceu o Alô Concursos, uma nova abordagem
            para informação de qualidade, design intuitivo e cobertura completa.
            Nossa jornada é alimentada pela busca incessante pela excelência e
            pelo compromisso de servir à comunidade de concurseiros com o que há
            de melhor em recursos e suporte. Estamos ansiosos para compartilhar
            essa jornada com vocês, ajudando a transformar suas aspirações em
            realidade."
          </p>
          <img
            src={historia}
            alt="Grupo de designers e programadores interagindo"
          />
        </div>
      </section>

      <section className="secondSection">
        <div className="flexSecond">
          <h2>Como funciona?</h2>
          <div className="flexTutorial">
            <TutorialBox
              passo={1}
              image={passo1}
              alt="Busca de concursos, imagem da pesquisa"
              tittle="Busque por concursos ou cargos"
              subtittle="Selecione a opção “por concurso” ou “por cargos” na barra de navegação principal.

A busca padrão é por “cargos” para o caso de não selecionar nenhuma das opções."
            />
            <TutorialBox
              passo={2}
              image={passo2}
              alt="Busca de concursos, imagem da pesquisa"
              tittle="Filtre sua busca"
              subtittle="Utilize os filtros disponíveis para refinar sua pesquisa de acordo com  suas preferências.

Você pode filtrar suas buscas pelo órgão responsável pelo concurso, a  localização geográfica desejada, o nível de escolaridade, a faixa  salarial, a abrangência do concurso, o tipo de contrato e outros."
            />
            <TutorialBox
              passo={3}
              image={passo3}
              alt="Busca de concursos, imagem da pesquisa"
              tittle="Informações detalhadas do concurso"
              subtittle="Para cada concurso você vai encontrar todos os documentos pertinentes, como editais e retificações, na página do concurso."
            />
          </div>
        </div>
        <p className="simple">Simples assim!</p>
        <img src={Statistics} alt={"fundo ondulado roxo para estatísticas"} />
        <div className="flexStatistcs">
          {/* {renderizacao ? renderizacao.map((item, index)=>{
                    console.log(item)

                }) : null} */}
          {console.log(renderizacao)}
          {renderizacao ? (
            <StatisticCard
              paddingTop={"12.8rem"}
              percent={renderizacao.contest_count}
              tittle="Total de concursos registrados na Alô Concursos"
            />
          ) : null}
          {renderizacao ? (
            <StatisticCard
              paddingTop="8rem"
              percent={renderizacao.total_vacancies}
              tittle="Total de vagas abertas nesse momento!"
            />
          ) : null}
          {renderizacao ? (
            <StatisticCard
              paddingTop="4rem"
              percent={convertMoney(renderizacao.max_wage)}
              tittle="Maior salário dos concursos registrados na Alô Concursos."
            />
          ) : null}
          {renderizacao ? (
            <StatisticCard
              paddingTop="0rem"
              percent={conversaoData(renderizacao.last_update)}
              tittle="Data que o último concurso foi adicionado na Alô Concursos"
            />
          ) : null}
        </div>
      </section>

      <section className="thirdSection">
        <div className="flexFAQ">
          <h2>Perguntas frequentes</h2>
          <div>
            <Faq
              question={
                "De onde vem as informações fornecidas pela Alô Concursos?"
              }
              answer={
                "Todas as informações disponibilizadas na Alô Concursos são provenientes de canais oficiais, como Díario de entes federados e bancas de concursos públicos."
              }
              alt={"Botão de abrir a pergunta"}
            />
            <Faq
              question={
                "Existe alguma cobrança pelo serviço prestado pela Alô Concursos?"
              }
              answer={
                "O uso da nossa Search Engine e filtros é inteiramente gratuito."
              }
              alt={"Botão de abrir a pergunta"}
            />
            <Faq
              question={
                "Além de informações sobre concursos que outros serviços a Alô Concursos oferece para concurseiros?"
              }
              answer={
                "Nossa equipe está trabalhando na implementação de outros serviços que visam dar suporte à comunidade de concurseiro. Está procurando algo em particular? Por favor, nos envie um email detalhando sua demanda."
              }
              alt={"Botão de abrir a pergunta"}
            />
          </div>
        </div>
      </section>

      <section className="fourthSection">
        <div className="Fjustify">
          <h2 className="formtittlefourth">Contato</h2>
          {/* <div className="flexAlign"> */}
          {/* <form onSubmit={formSubmit}>

                        <h1>Formulário</h1>
                        <p>Feedback ou sugestões? O nosso time vai adorar receber a sua mensagem.</p>
                        <div className='formFlexGlobal'>
                            <div className='formFlex'>
                                <label htmlFor="formName" required>Nome*</label>
                                <input id='formName' type="text" />
                            </div>

                            <div className='formFlex'>
                                <label htmlFor="formEmail">E-mail</label>
                                <input id='formEmail' type="email" />
                            </div>

                            <div className='formFlex'>
                                <label htmlFor="forTelefone" required>Telefone*</label>
                                <input id='forTelefone' type="number" />
                            </div>
                            
                            <div className='formFlex'>
                                <label htmlFor="formMensagem" required>Mensagem*</label>
                                <textarea id="formMensagem" cols="30" rows="10"></textarea>
                            </div>

                            <div className='formCheck'>
                                <input id='formCheck' type="checkbox" />
                                <label htmlFor="formCheck">Concorde com a nossa <em>política de privacidade.</em></label>
                            </div>
                        </div>

                        <button>Enviar</button>
                    </form> */}

          <div className="squareEmail">
            <img className="baloon" src={formemail} alt="Balão de conversa" />
            <div className="centralInfoSquare">
              <div className="flexEmailSquare">
                <h2 className="emailSquare">E-mail</h2>
                <p>
                  Se preferir, pode entrar em contato através do nosso E-mail.
                </p>
              </div>
              <button>
                <img src={mail} alt="ícone de carta de e-mail" />
                <a href="mailto:admin@aloconcursos.com">
                  admin@aloconcursos.com
                </a>
              </button>
            </div>
          </div>
        </div>
        {/* </div> */}
      </section>
    </StyledAbout>
  );
}

export default About;
