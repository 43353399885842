import { React, useState, useEffect } from "react";
import logo_alo from "../assets/logo_alo.svg";
import { StyledHomepage } from "../styles/Homepage";
import { useLocation } from "react-router-dom";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import SlideSwiper from "../components/SlideSwiper";
import SearchBar from "../components/SearchBar";

function Homepage({ searchReset, setSearchReset, setUrlAtual }) {
  // Lógica pra capturar a página antes do login
  const location = useLocation();
  useEffect(() => {
    setUrlAtual(location.pathname);
  }, []);
  // Daqui pra baixo é a lógica de: Consumir a API e renderizar os dados
  // Variáveis para consumo da API
  const Token = process.env.REACT_APP_TOKEN;
  const URL = process.env.REACT_APP_API_URL;

  let queryForApi = `${URL}/office/office/?limit=12`;
  const [renderizacao, setRenderizacao] = useState(null);

  useEffect(() => {
    async function requisicao(apiLink) {
      fetch(apiLink, {
        headers: {
          Authorization: `Token ${Token}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((response) => {
          setRenderizacao(response.results);
        });
    }

    requisicao(queryForApi);
  }, [queryForApi, Token]);

  // Setando o nome da página
  document.title = "Alô Concursos";

  // Daqui pra baixo é a lógica de: selecionar o tipo de pesquisa e envia-la para o Google Analytics
  // Função para captura de qual tipo de pesquisa foi selecionada
  const [paginaPesquisada, setPaginaPesquisada] = useState("offices");
  function SearchTypeControl(e) {
    e.target.value === "Cargos"
      ? setPaginaPesquisada("offices")
      : setPaginaPesquisada("contests");
  }

  return (
    <StyledHomepage className="Homepage">
      <div className="flexHome">
        <img className="Logo" src={logo_alo} alt="Logo Alô Concursos" />
        <i>Todos os concursos em um só lugar</i>
        <SearchBar
          searchReset={searchReset}
          setSearchReset={setSearchReset}
          pagina={paginaPesquisada}
        />
        <div className="flexInputRadioHome">
          <div>
            <input
              value={"Cargos"}
              onChange={(e) => SearchTypeControl(e)}
              type="radio"
              name="radioGroup"
              checked={paginaPesquisada === "offices" ? true : null}
            />
            <label>Cargos</label>
          </div>
          <div>
            <input
              value={"Concursos"}
              onChange={(e) => SearchTypeControl(e)}
              type="radio"
              name="radioGroup"
            />
            <label>Concursos</label>
          </div>
        </div>
      </div>
      <h2 className="Confira">Não perca as últimas oportunidades:</h2>

      <div className="slideContainer">
        <div className="tampaBug um"></div>
        <div className="tampaBug dois"></div>

        {renderizacao ? <SlideSwiper renderizacao={renderizacao} /> : null}
      </div>
    </StyledHomepage>
  );
}

export default Homepage;
