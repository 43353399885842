import styled from "styled-components";

export const StyledFilterCategorySquare = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.6rem;
  height: 1.5rem;
  button {
    border: none;
    background: transparent;
    cursor: pointer;
    img {
      width: 12px;
      height: 12px;
    }
  }
`;
