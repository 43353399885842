import styled from "styled-components";

export const StyledFaq = styled.div`
  padding: 3.2rem 0;
  width: 70rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin: 0 auto;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      color: #33394d;
      text-align: center;
      font-family: "Work Sans";
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: left;
    }
    img {
      width: 2.4rem;
      height: 2.4rem;
      cursor: pointer;
    }
  }

  p {
    overflow: hidden;
    height: 0;
    color: #667085;
    font-family: "Work Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.16px;
  }
  hr {
    border-color: rgba(212, 210, 210, 0.3);
    width: 100%;
  }

  .open {
    animation: open 0.6s ease-in-out forwards;

    transition: 2s;
  }
  .close {
    animation: close 0.6s ease-in-out forwards;

    transition: 2s;
  }

  @keyframes open {
    0% {
      height: 0;
    }
    100% {
      height: 5rem;
    }
  }

  @keyframes close {
    0% {
      height: 5rem;
    }
    100% {
      height: 0;
    }
  }
`;
