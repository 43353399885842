import { useNavigate } from "react-router-dom";
import {
  conversaoData,
  compararDatasClasse,
  compararDatasNome,
} from "../tools/functions";
import Tag from "../UI/Tag";
import { StyledCardSlide } from "../styles/CardSlide";

function CardSlide({
  contador,
  titulo,
  empresa,
  vagas,
  salario,
  banca,
  inscricoes,
  taxa,
  prova,
  local,
  imagem,
  registration_opening,
  registrationDeadline,
  id,
  tags,
}) {
  const navigate = useNavigate();

  return (
    <StyledCardSlide className="CardSlide">
      <div className="Cabecalho">
        <img src={imagem} alt="icone" />
        <Tag
          nome={compararDatasNome(registration_opening, registrationDeadline)}
          classname={compararDatasClasse(
            registration_opening,
            registrationDeadline
          )}
        />
      </div>
      <div className="Titulo">
        <h2>{titulo}</h2>
        <h3>{empresa}</h3>
      </div>
      <div className="tagCargo">
        {tags
          ? tags.map((tag, index) => {
              if (index < 2) {
                return (
                  <Tag key={index} nome={tag.name} classname="tagNormal" />
                );
              }
            })
          : null}
      </div>

      <div className="flexDe4slots">
        <div className="flexde2slots">
          <div>
            <h3>Vagas:</h3>
            <p>{vagas}</p>
          </div>
          <div>
            <h3>Salário:</h3>
            <p>{salario}</p>
          </div>
        </div>
        <div className="flexde2slots">
          <div>
            <h3>Banca:</h3>
            <p>{banca}</p>
          </div>
          <div>
            <h3>Inscrição até:</h3>
            <p>{conversaoData(registrationDeadline)}</p>
          </div>
        </div>
      </div>
      <button
        onClick={() => {
          navigate(`/concurso/${id}`);
        }}
      >
        Confira o concurso
      </button>
    </StyledCardSlide>
  );
}

export default CardSlide;
