import React from "react";
import x from "../assets/x.png";
import { Link } from "react-router-dom";

function PopUp({ setShow }) {
  return (
    <div className="popUpMakeAPlan">
      <p>
        <Link to="/pagamento">Assine</Link> para ter acesso
      </p>
      <button className="btn-close" onClick={() => setShow(false)}>
        <img src={x} alt="Fechar" />
      </button>
    </div>
  );
}

export default PopUp;
