import React from "react";
import { StyledFooter } from "../styles/Footer";

function Footer() {
  return (
    <StyledFooter>
      © Alô Concursos 2024. Todos os direitos reservados.
    </StyledFooter>
  );
}

export default Footer;
