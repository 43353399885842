import React from "react";
import { StyledNotFound } from "../styles/NotFound";
function NotFound() {
  return (
    <StyledNotFound>
      <h1>Erro 404 esta página não existe</h1>
    </StyledNotFound>
  );
}

export default NotFound;
