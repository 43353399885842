import styled from "styled-components";

export const StyledSearchBar = styled.form`
  div {
    display: flex;
    height: 4.8rem;
    width: fit-content;
  }
  input {
    border-width: 1px 1px 1px 1px;
    border-color: #a0a5bd;
    outline: none;
    padding: 0 0 0 2.4rem;
    font-size: 1.8rem;
    color: #212529;
    width: 44rem;
    border-style: solid;
    border-radius: 0.8rem 0 0 0.8rem;
    font-family: "Work Sans", sans-serif;
  }

  button {
    border: 1px solid var(--Primary-500);
    background-color: #e6e5ff;
    width: 7.2rem;
    border-radius: 0 0.8rem 0.8rem 0;
    padding: 0px 2.4rem 0px 2.4rem;
    cursor: pointer;
    img {
      width: 2.4rem;
    }
  }
`;
