import styled from "styled-components";

export const StyledCardConcurso = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 3.2rem 2.4rem;
  width: 100%;
  gap: 1.67rem;
  // border: 2px solid red;
  border-radius: 1.6rem;
  background-color: #fff;
  box-shadow: 0px 1.333px 5.333px 0px rgba(0, 0, 0, 0.25);

  .iconAndTag {
    // height: 2.75rem;
    // column-gap: 12.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .imagem {
      img {
        width: 1.6rem;
        height: 2.0757rem;
      }
    }
  }
  .tittle {
    display: flex;
    flex-direction: column;
    gap: 0.533rem;
    h2 {
      color: var(--Grey-900);
      font-family: "Work Sans", sans-serif;
      font-size: 2.4rem;
      font-style: bold;
      font-weight: 700;
      line-height: 100%;
      min-height: 4.8rem;
    }
    h4 {
      color: var(--Grey-500);
      font-family: "Work Sans", sans-serif;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: 0.16px;
    }
  }
  .divisaoInfo {
    .InfoNumber {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: space-between;
      border-top: 0.667px solid var(--Grey-200);
      border-bottom: 0.667px solid var(--Grey-200);
      padding: 1.6rem 0;
      gap: 1.6rem;

      .infoSubMenu {
        display: flex;
        flex-direction: column;
        // gap: .533rem;
        align-items: start;
        p {
          color: var(--Grey-500);
          text-align: center;
          font-family: "Work Sans", sans-serif;
          font-size: 1.4rem;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
        h2 {
          color: var(--Grey-700);
          text-align: center;
          font-family: "Work Sans", sans-serif;
          font-size: 2rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .texto {
      color: var(--Grey-500);
      font-family: "Work Sans", sans-serif;
      font-size: 10.667px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      padding: 1.6rem 0;
    }
  }
  a {
    text-decoration: none;
  }
  .saberMais {
    margin-top: 4.8rem;
    padding: 1.2rem 2.4rem;
    align-items: center;
    background-color: #fff;
    height: 3rem;
    width: fit-content;
    display: flex;
    gap: var(--Scale-2);
    border-radius: var(--Radius-md);
    border: 0.667px solid var(--Primary-500);
    color: var(--Primary-500);
    text-align: center;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 14.933px */
    letter-spacing: 0.16px;
    cursor: pointer;
  }
  .popUpMakeAPlan {
    width: 22rem;
    border-radius: 0.4rem;
    background-color: rgba(237, 238, 250, 0.9);
    padding: 1rem 1.6rem 1rem 1.2rem;
    position: absolute;
    color: black;
    font-family: "Work Sans", sans-serif;
    font-size: 1.6rem;
    top: 41.5rem;
    left: 35%;
    right: 0;
    z-index: 99;
    border: 1px solid var(--Grey-200);

    a {
      color: var(--Primary-500);
    }
    .btn-close {
      position: absolute;
      left: calc(100% - 0.3rem);
      top: 0.3rem;
      transform: translateX(-100%);
      background-color: transparent;
      border: none;
      cursor: pointer;

      img {
        width: 1.6rem;
      }
    }
  }
`;
