import styled from "styled-components";

export const StyledOffice = styled.main`
  margin: 2rem auto 0 auto;
  width: 90%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .CardMenu {
    margin-left: 1.067rem;
    .tituloPrincipal {
      color: var(--Grey-500);
      font-family: "Work Sans", sans-serif;
      font-size: 3.2rem;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: -1.44px;
    }
    .cardsGrid {
      padding: 3.2rem 1.0667rem 0rem 1.0667rem;
      display: grid;
      grid-template: repeat(2, 57.9rem) / repeat(auto-fill, minmax(32rem, 1fr));
      row-gap: 2rem;
      column-gap: 2rem;
    }
    .cardConcursoGrid {
      display: grid;
      padding: 3.2rem 1.0667rem 0rem 1.0667rem;
      row-gap: 2rem;
      column-gap: 2rem;
      grid-template: repeat(2, 1fr) / repeat(auto-fill, minmax(32rem, 1fr));
    }
  }
`;
