import React from "react";
import { StyledFlexMenu } from "../styles/FlexMenu";

function FlexMenu({ setPage, page, icon, active, colorRed }) {
  return (
    <>
      <StyledFlexMenu className="flexMenu">
        <img src={icon} alt={page} />
        <button
          className={active ? "active" : "inactive"}
          onClick={() => (page === "Sair da Conta" ? null : setPage(page))}
          style={colorRed ? { color: "#A11722" } : null}
        >
          {page}
        </button>
      </StyledFlexMenu>
    </>
  );
}

export default FlexMenu;
