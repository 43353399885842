import { StyledAboutCards } from "../styles/AboutCards";

function AboutCards({ image, tittle, subTittle }) {
  return (
    <StyledAboutCards className="AboutCards">
      <img src={image} alt={tittle} />
      <h4>{tittle}</h4>
      <p>{subTittle}</p>
    </StyledAboutCards>
  );
}

export default AboutCards;
