import styled from "styled-components";

export const StyledStatisticCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  h3 {
    color: #454ed1;
    font-family: "Work Sans";
    font-size: 6.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 90%; /* 86.4px */
    letter-spacing: -1.92px;
  }
  h4 {
    color: #33394d;
    font-family: "Work Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 28px */
  }
  p {
    color: #606780;
    font-family: "Work Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.16px;
  }
`;
