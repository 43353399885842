import styled from "styled-components";

export const StyledInnerCardInfo = styled.div`
  display: flex;
  padding: var(--Scale-3, 24px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.8rem;
  border-radius: 0.8rem;
  background: var(--Grey-25);

  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.15);

  h5 {
    color: var(--Grey-700, #33394d);
    font-family: "Work Sans";
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
  p {
    color: var(--Grey-500, #606780);
    font-family: "Work Sans";
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
`;
