import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Nav from "../components/Nav";
import { connect } from "react-redux";
import {
  checkAuthenticated,
  load_user,
  googleAuthenticate,
} from "../actions/auth";
import queryString from "query-string";
import Footer from "../components/Footer";

const Layout = ({
  checkAuthenticated,
  load_user,
  children,
  googleAuthenticate,
  setUrlAtual,
}) => {
  let location = useLocation();
  useEffect(() => {
    const values = queryString.parse(location.search);
    const state = values.state ? values.state : null;
    const code = values.code ? values.code : null;

    if (state && code) {
      googleAuthenticate(state, code);
    } else {
      checkAuthenticated();
      load_user();
    }
  }, [location]);

  return (
    <>
      <Nav setUrlAtual={setUrlAtual} />
      {children}
      <Footer />
    </>
  );
};

export default connect(null, {
  checkAuthenticated,
  load_user,
  googleAuthenticate,
})(Layout);
